import { bool, func, string } from 'prop-types'

import { crunchSrcSet } from './utils'
import { PhotoPropType } from './Photo'

const Thumb = ({ active, alt, fallbackURL, onClick, sizes, srcset }) => {
  srcset = crunchSrcSet(srcset)
  return (
    <button
      className={`c-glide__thumbnail${active ? ' is-active' : ''}`}
      onClick={onClick}
    >
      <img src={fallbackURL} srcSet={srcset} sizes={sizes} alt={alt} />
    </button>
  )
}
Thumb.propTypes = {
  active: bool,
  alt: string,
  ...PhotoPropType,
  onClick: func,
}

export default Thumb
