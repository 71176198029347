import AddressesChoice from './addresses/AddressesChoice'
import CarrierChoice from './carriers/CarrierChoice'
import mountReactContainer from '../../../../shared/js/react-mounting'
import PriceDetails from './shared/PriceDetails'
import Summary from './cart/Summary'

document.addEventListener('DOMContentLoaded', () => {
  mountReactContainer({ id: 'js-cart-view', component: Summary })
  mountReactContainer({ id: 'js-cart-addresses', component: AddressesChoice })
  mountReactContainer({ id: 'js-cart-carriers', component: CarrierChoice })
  mountReactContainer({ id: 'js-price-details', component: PriceDetails })
})
