import Cookies from 'js-cookie'

// Here are the trackers/tags used by the app
// - Analytics/GA4
// - Matomo (kind of GA4 clone)
// - Bing tracker
// - Meta/Facebook pixel tag
// - Criteo tracker (NOT EXTRACTED YET)
// - Spread widget (NOT EXTRACTED YET)

export function loadTags() {
  const container = document.getElementById('js-consent-banner')
  const attrs = container?.getAttribute('data-tags') || '{}'
  const props = JSON.parse(attrs) || {}

  loadKelkoo()

  if (!props) {
    return
  }

  const { adwordsId, ga4Id, bingId, pixelId } = props

  ga4Id && loadGoogleAnalyticsTag({ adwordsId, ga4Id })
  bingId && loadBingTracker(bingId)
  pixelId && loadFacebookPixelTag(pixelId)

  if (ga4Id && props.gtagEvent) {
    window.gtag?.(...props.gtagEvent)
  }

  if (adwordsId && props.adwordsEvent) {
    window.gtag?.(...props.adwordsEvent)
  }

  if (bingId && props.bingEvent) {
    window.uetq = window.uetq || []
    window.uetq.push(props.bingEvent)
  }
}

// Analytics stuff and Google consent mode
function loadGoogleAnalyticsTag({ adwordsId, ga4Id }) {
  const t = document.getElementsByTagName('script')[0]
  const e = document.createElement('script')
  e.async = true
  e.src = `https://www.googletagmanager.com/gtag/js?id=G-${ga4Id}`
  t.parentNode.insertBefore(e, t)
  window.dataLayer = window.dataLayer || []

  function gtag() {
    window.dataLayer.push(arguments)
  }
  window.gtag = gtag

  // GDPR Consent mode
  if (Cookies.get('gdpr.cookie_consent.ok') !== 'true') {
    // Default ad_storage to 'denied'.
    gtag('consent', 'default', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
    })
  }

  gtag('js', new Date())
  ga4Id && gtag('config', ga4Id)
  adwordsId && gtag('config', `AW-${adwordsId}`)
}

// Facebook/Meta pixel tag
function loadFacebookPixelTag(identifier) {
  /* eslint-disable */
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    }
    if (!f._fbq) f._fbq = n
    n.push = n
    n.loaded = !0
    n.version = '2.0'
    n.queue = []
    t = b.createElement(e)
    t.async = !0
    t.src = v
    s = b.getElementsByTagName(e)[0]
    s.parentNode.insertBefore(t, s)
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js'
  )

  fbq('init', identifier)
  fbq('track', 'PageView')
}

function loadKelkoo() {
  const t = document.getElementsByTagName('script')[0]
  const e = document.createElement('script')
  e.async = true
  e.src = 'https://s.kk-resources.com/leadtag.js'
  t.parentNode.insertBefore(e, t)
}

function loadBingTracker(bingId) {
  ;(function (w, d, t, r, u) {
    var f, n, i
    ;(w[u] = w[u] || []),
      (f = function () {
        var o = { ti: bingId }
        ;(o.q = w[u]), (w[u] = new UET(o)), w[u].push('pageLoad')
      }),
      (n = d.createElement(t)),
      (n.src = r),
      (n.async = 1),
      (n.onload = n.onreadystatechange =
        function () {
          var s = this.readyState
          ;(s && s !== 'loaded' && s !== 'complete') ||
            (f(), (n.onload = n.onreadystatechange = null))
        }),
      (i = d.getElementsByTagName(t)[0]),
      i.parentNode.insertBefore(n, i)
  })(window, document, 'script', '//bat.bing.com/bat.js', 'uetq')
}
