import { bool, func, oneOf, string } from 'prop-types'

let syntheticIdSuffix = 0

const TextField = ({
  helperText,
  defaultValue,
  disabled,
  errorMessage,
  id,
  label,
  name,
  onChange,
  required,
  type,
  value,
  valid,
}) => {
  const descriptionId = helperText
    ? `description-input-${id || ++syntheticIdSuffix}`
    : null

  const dynamicLabelProps = helperText
    ? { 'aria-describedby': descriptionId }
    : {}
  const dynamicInputProps = {}
  if (defaultValue != null) {
    dynamicInputProps.defaultValue = defaultValue
  }
  if (value != null) {
    dynamicInputProps.value = value
  }
  if (typeof onChange === 'function') {
    dynamicInputProps.onChange = ({ target: { value } }) =>
      onChange(value.trimLeft())
  }

  return (
    <div className='u-mb-2'>
      <label
        className='c-label c-label--image'
        htmlFor={id}
        {...dynamicLabelProps}
      >
        {label}
      </label>
      <input
        aria-required={required}
        aria-invalid={!valid}
        disabled={disabled}
        id={id}
        name={name}
        type={type}
        {...dynamicInputProps}
      />
      {!valid && <p className='c-message c-message--error'>{errorMessage}</p>}
      {helperText && (
        <p id={descriptionId} className='c-message c-message--help'>
          {helperText}
        </p>
      )}
    </div>
  )
}

TextField.propTypes = {
  defaultValue: string,
  disabled: bool,
  errorMessage: string,
  helperText: string,
  id: string,
  label: string.isRequired,
  name: string,
  onChange: func,
  required: bool,
  type: oneOf(['date', 'email', 'number', 'text']),
  value: string,
  valid: bool,
}
TextField.defaultProps = {
  disabled: false,
  errorMessage: 'Vous devez renseigner un texte',
  required: false,
  type: 'text',
  valid: true,
}

export default TextField
