// Prevent multiple means selection due to multiple forms presence on paymeny
// means page
function handlePaymentMeansInputs() {
  const inputs = document.querySelectorAll('input[name="payment-mode"]')

  function uncheckInputs(id) {
    for (const input of inputs) {
      input.checked = input.id === id
    }
  }

  for (const input of inputs) {
    input.addEventListener('click', () => uncheckInputs(input.id))
  }
}

handlePaymentMeansInputs()
