import { arrayOf, number, shape, string } from 'prop-types'
import { useState } from 'react'

import {
  computeOptionsCents,
  computeOptionsToParams,
} from '../../../../../shared/js/options-utils'
import DiscountForm from './DiscountForm'
import {
  DiscountsPropTypes,
  LineItemPropTypes,
} from '../../../shared/prop-types'
import { drop, patch } from '../../../../../shared/js/json-fetch'
import imgShoppingCart from '../../../../svg/icon/shopping-cart.svg'
import JewelleryBagLineItem from './JewelleryBagLineItem'
import LineItem from './LineItem'
import LoyaltyForm from './LoyaltyForm'
import { notifyCartBadge } from '../../Cart'
import PriceDetails from '../shared/PriceDetails'
import useTimeout from '../../../../../shared/js/use-timeout'

const Summary = ({
  cartApplyDiscountURL,
  cartApplyLoyaltiesURL,
  cartRemoveDiscountURL,
  cartRemoveURL,
  cartUpdateURL,
  nextStepURL,
  deliveryLabel: initialDeliveryLabel,
  discounts: initialDiscounts,
  lineItems: initialLineItems = [],
  loyalty: initialLoyalty,
}) => {
  const [deliveryLabel, setDeliveryLabel] = useState(initialDeliveryLabel)
  const [lineItems, setLineItems] = useState(initialLineItems)
  const [discounts, setDiscounts] = useState(initialDiscounts)
  const [loyalty, setLoyalty] = useState(initialLoyalty)
  const [actionsDisabled, setActionsDisabled] = useState(false)
  const [flash, setFlash] = useState(null)

  // Remove flash messages after 10 seconds
  useTimeout(() => setFlash(null), 10_000)

  if (lineItems?.length === 0) {
    return (
      <div className='u-background-light u-pb-10@us u-pb-14@fs u-mb-4@fs'>
        <div className='o-wrapper '>
          <a href='/' className='c-link c-link--back u-mb-2@us u-mb-4@fs'>
            Retour à mes achats
          </a>
          <h1 className='c-h3 c-h3--light u-mb-3@us u-mb-6@fs'>Mon panier</h1>
          <div className='u-txt-center u-mb-4@us u-mb-14@fs'>
            <img
              className='c-img-shopping-cart u-center u-mb-2'
              width='160'
              height='160'
              src={imgShoppingCart}
              alt='Panier vide'
            />
            <p className='u-mb-2@us u-mb-4@fs'>Votre panier est vide</p>
            <a href='/' className='c-btn c-btn--back'>
              <svg className='c-btn__icon' aria-hidden='true' focusable='false'>
                <use xlinkHref='#arrow' />
              </svg>
              Retour à mes achats
            </a>
          </div>
        </div>
      </div>
    )
  }

  const { cents, taxCents, totalCents } = computeTotalCents()
  const discountCents = discounts
    ? discounts.reduce((curr, { cents }) => curr + cents, 0)
    : 0

  // How many line item expect a jewellery bag?
  const jewelleryBagCount = lineItems.reduce(
    (acc, { jewelleryBag }) => acc + (jewelleryBag ? 1 : 0),
    0
  )

  return (
    <div className='u-background-light u-pb-10@us u-pb-14@fs u-mb-4@fs'>
      <div className='o-wrapper'>
        <h1 className='c-h3 c-h3--light u-mb-1'>
          Mon panier ({lineItems.length})
        </h1>
        {flash && (
          <div className={`c-message c-message--${flash.kind}`}>
            {flash.message}
          </div>
        )}
        <form className='o-layout'>
          <div className='o-layout__item-9-cols o-layout__shrink-2 u-mb-3@us'>
            <table className='c-cart-summary _c-cart-summary '>
              <caption className='u-visually-hidden'>
                Détail des produits
              </caption>
              <thead className='u-visually-hidden'>
                <tr>
                  <th scope='col'>Image</th>
                  <th scope='col'>Désignation</th>
                  <th scope='col'>Quantité</th>
                  <th scope='col'>Prix unitaire</th>
                  <th scope='col'>Supprimer le produit</th>
                </tr>
              </thead>
              <tbody>
                {lineItems.map((lineItem) => (
                  <LineItem
                    key={lineItem.id}
                    disabled={actionsDisabled}
                    {...lineItem}
                    onDelete={handleLineItemRemoval}
                    onOptionChange={handleOptionChange}
                    onQuantityChange={handleQuantityChange}
                  />
                ))}
                <JewelleryBagLineItem count={jewelleryBagCount} />
              </tbody>
            </table>
          </div>
          <div className='o-layout__item-3-cols'>
            <PriceDetails
              cents={cents}
              deliveryLabel={deliveryLabel}
              discounts={discounts}
              nextStepActionLabel='Commander mon panier'
              nextStepURL={nextStepURL}
              onDiscountDelete={handleDiscountDelete}
              taxCents={taxCents}
              totalCents={totalCents - discountCents}
            >
              <DiscountForm
                onChange={handleDiscountsChange}
                url={cartApplyDiscountURL}
              />
              <LoyaltyForm
                ceilingCents={totalCents + loyalty.used.cents}
                onChange={handleDiscountsChange}
                slices={loyalty.slices}
                url={cartApplyLoyaltiesURL}
              />
            </PriceDetails>
          </div>
        </form>
      </div>
    </div>
  )

  // Computes the global totals (net, tax, gross) for the order
  function computeTotalCents() {
    const result = { cents: 0, taxCents: 0, totalCents: 0 }
    for (const { options, quantity, unitCents, unitTaxCents } of lineItems) {
      const { optionsCents, optionsTaxCents } = computeOptionsCents(options)
      const extraCents = quantity * (unitCents + optionsCents)
      const extraTaxCents = quantity * (unitTaxCents + optionsTaxCents)

      result.cents += extraCents
      result.taxCents += extraTaxCents
      result.totalCents += extraCents + extraTaxCents
    }
    return result
  }

  // Performs a line item removal with the server.
  function handleLineItemRemoval(id) {
    processServerUpdate(
      drop(cartRemoveURL, { line_item: { id } }),
      ({ data: { deliveryLabel } }) => {
        const remainingItems = lineItems.filter((li) => li.id !== id)
        setLineItems(remainingItems)
        setDeliveryLabel(deliveryLabel)
      }
    )
  }

  function handleDiscountsChange({ discounts, loyalty: updatedLoyalty }) {
    const newLoyalty = updatedLoyalty ?? loyalty
    setDiscounts(discounts)
    setLoyalty(newLoyalty)
  }

  // Performs a discount removal with the server.
  function handleDiscountDelete(discountId) {
    processServerUpdate(
      drop(cartRemoveDiscountURL, { discount_id: discountId }),
      ({ data }) => {
        const remainingDiscounts = discounts.filter(
          ({ id }) => id !== discountId
        )
        const updatedLoyalty = data?.loyalty ?? loyalty
        setDiscounts(remainingDiscounts)
        setLoyalty(updatedLoyalty)
      }
    )
  }

  // Finalizes an option change with the server.
  function handleOptionChange(id, options) {
    const operation = patch(cartUpdateURL, {
      line_items: {
        [id]: {
          product_options: {
            selection: computeOptionsToParams(options),
          },
        },
      },
    })

    processServerUpdate(operation, ({ data: { deliveryLabel, lineItems } }) => {
      setLineItems(lineItems)
      setDeliveryLabel(deliveryLabel)
    })
  }

  // Performs a line item quantity change with the server.
  function handleQuantityChange(id, quantity) {
    processServerUpdate(
      patch(cartUpdateURL, { line_items: { [id]: { quantity } } }),
      ({ data: { deliveryLabel, lineItems } }) => {
        setLineItems(lineItems)
        setDeliveryLabel(deliveryLabel)
      }
    )
  }

  // “Internal” wrapper for server updates, that keeps all recurring logic DRY:
  // ensuring a single server action at any time, displaying the flash messages,
  // notifying the cart badge of a global quantity ("size") change, etc.
  // Action-specific success logic is passed as a callback that gets the entire
  // result payload (but mostly needs its potential `data` field).
  async function processServerUpdate(operation, onSuccess) {
    if (actionsDisabled) {
      return
    }

    setActionsDisabled(true)
    try {
      const result = await operation
      const { data, flash } = result
      if (data) {
        onSuccess(result)
      }
      if (flash) {
        setFlash(flash)
      }
      if (data?.size != null) {
        notifyCartBadge(data.size)
      }
    } finally {
      setActionsDisabled(false)
    }
  }
}

Summary.propTypes = {
  cartApplyDiscountURL: string.isRequired,
  cartApplyLoyaltiesURL: string.isRequired,
  cartRemoveDiscountURL: string.isRequired,
  cartRemoveURL: string.isRequired,
  cartUpdateURL: string.isRequired,
  deliveryLabel: string,
  discounts: DiscountsPropTypes,
  lineItems: arrayOf(shape(LineItemPropTypes)),
  loyalty: shape({
    slices: arrayOf(arrayOf(number)).isRequired,
    used: shape({
      points: number.isRequired,
      cents: number,
    }).isRequired,
  }).isRequired,
  nextStepURL: string.isRequired,
}

export default Summary
