import { any, string } from 'prop-types'
import { useState } from 'react'

import Dialog from '../shared/Dialog'

const GuideDialogLink = ({ html, title, url }) => {
  const [opened, setOpened] = useState(false)

  return (
    <>
      <a href={url} onClick={toggleDialog} aria-haspopup='dialog'>
        <span className='c-img-like c-img-like--2/3'>{title}</span>
      </a>
      <Dialog
        className='right-modal'
        onCancel={toggleDialog}
        opened={opened}
        title={title}
      >
        <div className='u-font-sm' dangerouslySetInnerHTML={{ __html: html }} />
      </Dialog>
    </>
  )

  function toggleDialog(event) {
    event.preventDefault()
    setOpened(!opened)
  }
}

GuideDialogLink.propTypes = {
  html: any.isRequired,
  title: string.isRequired,
  url: string.isRequired,
}

export default GuideDialogLink
