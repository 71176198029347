import { arrayOf, func, number, shape, string } from 'prop-types'
import { useState } from 'react'

import ActiveFilters from './ActiveFilters'
import {
  ActiveFiltersPropTypes,
  FacetsPropTypes,
  SalePropTypes,
} from '../../shared/prop-types'
import Dialog from '../shared/Dialog'
import Filters from './Filters'
import { get } from '../../../../shared/js/json-fetch'
import SaleCountdown from '../shared/SaleCountdown'

const PER_PAGE_BASIS = 30
const PER_PAGE_MAX = PER_PAGE_BASIS * 5

const Catalog = ({
  activeFilters: initialActiveFilters = {},
  entriesCount: initialEntriesCount,
  facets,
  paginationHTML: initialPaginationHTML,
  perPage: initialPerPage,
  resultsHTML: initialResultsHTML,
  resultsLabel: initialResultsLabel,
  sale,
  sortBy: initialSortBy,
}) => {
  const [flash, setFlash] = useState(null)
  // The shape of active filters is: { facetId: [facet active filters] }
  const [activeFilters, setActiveFilters] = useState(initialActiveFilters)
  // When filters modal is active (ie on small devices) we expect search not
  // to be triggered until manual validation is done. We therefore have to
  // store active filters before modal is opened in order to make cancellation
  // possible when the user decides not to run search but cancel it.
  const [currentActiveFilters, setCurrentActiveFilters] =
    useState(initialActiveFilters)
  // Manage filters modal on small devices
  const [modalActive, setModalActive] = useState(false)
  // Manage sort filter independently from other filters due to separated display position
  const [sortBy, setSortBy] = useState(initialSortBy)
  // Search results are reloaded on filter change
  const [resultsHTML, setResultsHTML] = useState(initialResultsHTML)
  const [resultsLabel, setResultsLabel] = useState(initialResultsLabel)
  const [entriesCount, setEntriesCount] = useState(initialEntriesCount)
  const [paginationHTML, setPaginationHTML] = useState(initialPaginationHTML)
  const [perPage, setPerPage] = useState(initialPerPage)
  // Prepare pagination choice depending on total entries.
  // Limit to max 120 items per page.
  const perPageList = Array.from(
    Array(Math.ceil(Math.min(entriesCount, PER_PAGE_MAX) / PER_PAGE_BASIS)),
    (_, index) => (index + 1) * PER_PAGE_BASIS
  )

  const catalogContainerClass =
    facets.length > 0 ? 'o-layout__item-9-cols' : 'o-layout-wrap'
  const itemsPerRow = facets.length > 0 ? 3 : 4
  const resultsContainerClass = `o-layout-wrap o-layout-wrap--${itemsPerRow}-items u-mb-6@us u-mb-11@fs`

  return (
    <>
      {facets.length > 0 && (
        <>
          <div className='o-layout__item-3-cols'>
            <ActiveFilters
              filters={activeFilters}
              onFilterDelete={deleteFilter}
              onFilterReset={clearFilters}
            />
            <div id='filter' className='u-hidden@us'>
              <Filters
                activeFilters={activeFilters}
                clearFilters={clearFilters}
                deleteFilter={deleteFilter}
                facets={facets}
                selectFilter={selectFilter}
              />
            </div>
          </div>
        </>
      )}
      <div className={catalogContainerClass}>
        <div className='o-table o-table--half u-mb-2 u-hidden@fs'>
          <div className='o-table__cell'>
            <SortBySelect
              id='sort-by-mobile'
              onChange={handleSortBy}
              sortBy={sortBy}
            />
          </div>
          <div className='o-table__cell'>
            <button
              className='c-btn c-btn--filter'
              type='button'
              aria-haspopup='dialog'
              onClick={openModal}
            >
              Filtrer
            </button>
          </div>
        </div>
        {flash && (
          <div className={`c-message c-message--${flash.kind}`}>
            {flash.message}
          </div>
        )}
        {sale && <SaleCountdown {...sale} />}
        <h2 className='u-visually-hidden'>Résultats</h2>
        <div className='o-layout o-layout--end-y u-mb-2'>
          <PerPageSelect
            count={entriesCount}
            label={resultsLabel}
            id='per_page-top'
            perPage={perPage}
            perPageList={perPageList}
            setPerPage={handlePerPage}
          />
          {entriesCount > 1 && (
            <span className='u-hidden@us'>
              <SortBySelect
                id='sort-by-screen'
                onChange={handleSortBy}
                sortBy={sortBy}
              />
            </span>
          )}
        </div>
        <div
          className={resultsContainerClass}
          dangerouslySetInnerHTML={{ __html: resultsHTML }}
        />
        <div className='o-layout o-layout--end-y'>
          <PerPageSelect
            className='u-hidden@um'
            count={entriesCount}
            id='per_page-bottom'
            label={resultsLabel}
            perPage={perPage}
            perPageList={perPageList}
            setPerPage={handlePerPage}
          />
          {paginationHTML && (
            <div dangerouslySetInnerHTML={{ __html: paginationHTML }} />
          )}
        </div>
      </div>
      <Dialog
        className='right-action-modal'
        onCancel={dismissModal}
        opened={modalActive}
        title='Filtres'
      >
        <Filters
          activeFilters={activeFilters}
          clearFilters={clearFilters}
          deleteFilter={deleteFilter}
          facets={facets}
          prefix='modal'
          selectFilter={selectFilter}
          modal
        />
        <div className='right-action-modal__btn'>
          <button
            className='c-btn c-btn--fill'
            type='reset'
            onClick={dismissModal}
          >
            Annuler
          </button>
          <button className='c-btn c-btn--primary' onClick={handleFilters}>
            Appliquer
          </button>
        </div>
      </Dialog>
    </>
  )

  // Clear all active filters.
  // Reload search form on large devices.
  function clearFilters() {
    search({ filters: {}, sortBy })
  }

  // Compute URL search params with selected filters
  function computeSearchURL(activeFilters, perPage, sortBy) {
    // Skip URL update on old browsers
    if (!history.pushState || typeof URLSearchParams === 'undefined') {
      return
    }

    const url = new URL(window.location)
    // Fetch queries should use a distinctive .json suffix so the browser
    // doesn't confuse HTML and JSON responses in their cache / back behavior.
    url.pathname += '.json'
    const searchParams = new URLSearchParams(url.search.slice(1))
    // Everytime JS filters change, we must force navigation back
    // to the first page otherwise it could result in an empty page
    // even if filters match some products.
    searchParams.delete('page')
    // Manage per page filter
    searchParams.delete('per_page')
    if (perPage) {
      searchParams.append('per_page', perPage)
    }
    // Manage sort filter
    searchParams.delete('sort_by')
    if (sortBy) {
      searchParams.append('sort_by', sortBy)
    }
    // Loop over every facet to check against active facets+filters.
    // If no filter is selected, then disable facet from search params.
    // We have to loop twice for facets param key removal since `taxon_ids[]`
    // can be used by many facets and would be reset for every related facet.
    for (const { paramKey } of facets) {
      // First, remove search param
      searchParams.delete(paramKey)
    }
    for (const { id, paramKey } of facets) {
      // Then append every search param for facet with active filters values
      const facetActiveFilters = activeFilters[id]
      if (facetActiveFilters) {
        for (const { value } of facetActiveFilters) {
          searchParams.append(paramKey, value)
        }
      }
    }
    url.search = searchParams.toString()
    return url
  }

  // Remove selected filter.
  // Reload search form on large devices.
  function deleteFilter({ id, value }) {
    // Price filter is managed separately and fully purged on delete call
    let updatedActiveFilters =
      id === 'prices'
        ? []
        : activeFilters[id].filter((filter) => filter.value !== value)

    if (updatedActiveFilters.length > 0) {
      updatedActiveFilters = { ...activeFilters, [id]: updatedActiveFilters }
    } else {
      // Remove facet from active filters when no associated filters exist
      updatedActiveFilters = { ...activeFilters }
      delete updatedActiveFilters[id]
    }
    search({ filters: updatedActiveFilters, perPage, sortBy })
  }

  // Hide modal, reset active filters
  function dismissModal() {
    setModalActive(false)
    setActiveFilters(currentActiveFilters)
  }

  function handleFilters() {
    search({ filters: activeFilters, perPage, sortBy, forceModalSearch: true })
  }

  function handlePerPage({ target: { value } }) {
    const perPage = Number(value)
    search({ filters: activeFilters, perPage, sortBy })
  }

  function handleSortBy({ target: { value: sortBy } }) {
    search({ filters: activeFilters, perPage, sortBy })
  }

  // Open modal, store active filters for later (possible) cancellation
  function openModal() {
    setModalActive(true)
    setCurrentActiveFilters(activeFilters)
  }

  // Add newly selected filter.
  // Reload search form on large devices.
  function selectFilter({ id, label, value }) {
    // Purge selected price
    const currentFilters = id === 'prices' ? [] : activeFilters[id] || []
    const selectedFilters = [...currentFilters, { label, value }]
    const updatedActiveFilters = {
      ...activeFilters,
      [id]: selectedFilters,
    }
    search({ filters: updatedActiveFilters, perPage, sortBy })
  }

  async function search({
    filters,
    perPage,
    sortBy,
    forceModalSearch = false,
  }) {
    // Update filters display
    setActiveFilters(filters)
    // Run search when modal is not active (ie NOT on small devices)
    // or if explicitly asked to.
    if (!modalActive || forceModalSearch) {
      // Get search URL
      const url = computeSearchURL(filters, perPage, sortBy)
      // Call server for catalog partial view update
      const { data, error, flash } = await get(url)
      if (error) {
        setFlash(flash)
        // Restore previous active filters
        setActiveFilters(activeFilters)
      } else {
        // Reload catalog
        setEntriesCount(data.entriesCount)
        setPaginationHTML(data.paginationHTML)
        setResultsHTML(data.resultsHTML)
        setResultsLabel(data.resultsLabel)
        // Store selected "per page" and sort order
        setPerPage(perPage)
        setSortBy(sortBy)
        // Change browser URL -- ensure no inline JSON format suffix
        history.pushState({}, '', url.toString().replace(/\.json(\??)/, '$1'))
        // Clear flash
        setFlash(null)
        // Hide modal
        setModalActive(false)
      }
    }
  }
}

Catalog.propTypes = {
  activeFilters: ActiveFiltersPropTypes,
  entriesCount: number.isRequired,
  facets: FacetsPropTypes.isRequired,
  paginationHTML: string,
  perPage: number,
  resultsHTML: string.isRequired,
  resultsLabel: string.isRequired,
  sale: shape(SalePropTypes),
  sortBy: string,
}

export default Catalog

const PerPageSelect = ({
  className = '',
  count,
  id,
  label,
  perPage,
  perPageList,
  setPerPage,
}) => (
  <div className={`c-result ${className}`}>
    <strong className='c-result__total'>{label}</strong>
    {count > PER_PAGE_BASIS && (
      <span className='u-hidden@us'>
        <label htmlFor={id}>Résultats par page</label>
        <select
          className='c-select c-select--inline'
          defaultValue={perPage}
          id={id}
          name='per_page'
          onChange={setPerPage}
        >
          {perPageList.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
      </span>
    )}
  </div>
)

PerPageSelect.propTypes = {
  className: string,
  count: number.isRequired,
  id: string.isRequired,
  label: string.isRequired,
  perPage: number.isRequired,
  perPageList: arrayOf(number),
  setPerPage: func.isRequired,
}

const SortBySelect = ({ id, onChange, sortBy }) => (
  <div className='o-table__cell'>
    <label htmlFor={id} className='u-visually-hidden'>
      Trier
    </label>
    <select
      id={id}
      className='c-select'
      defaultValue={sortBy}
      onChange={onChange}
    >
      <option value='' hidden>
        Trier
      </option>
      <option value='price-asc'>Du - cher au + cher</option>
      <option value='price-desc'>Du + cher au - cher</option>
      <option value='page_views-desc'>Top ventes</option>
      <option value='name-asc'>Par nom</option>
      <option value='created_at-desc'>Par nouveauté</option>
    </select>
  </div>
)

SortBySelect.propTypes = {
  id: string.isRequired,
  onChange: func.isRequired,
  sortBy: string.isRequired,
}
