import { arrayOf, func, shape, string } from 'prop-types'

import { PhotoPropType } from './Photo'

export function crunchSrcSet(srcset) {
  return srcset.map(([url, size]) => `${url} ${size}`).join(', ')
}

export function makeArrow({ alt, direction, icon }) {
  const result = ({ className, onClick }) => {
    const disabling = className.includes('slick-disabled')
      ? ' c-pagination__item--disabled'
      : ''

    return (
      <li
        className={`c-pagination__item c-pagination__item--${direction}${disabling}`}
      >
        <button
          className={`c-pagination__link c-pagination__link--${direction}`}
          onClick={onClick}
        >
          <img
            src={icon}
            className='c-pagination__icon'
            alt={alt}
            width={12}
            height={12}
          />
        </button>
      </li>
    )
  }
  result.propTypes = { onClick: func }

  return result
}

export const MAX_REGULAR_THUMBS = 5

export const SliderCommonPropTypes = {
  arrowsImageURL: string.isRequired,
  slides: arrayOf(
    shape({
      audio: string,
      alt: string,
      main: shape(PhotoPropType),
      thumb: shape(PhotoPropType),
      video: string,
    })
  ).isRequired,
}
