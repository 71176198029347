import { bool, func, oneOf } from 'prop-types'

import AddressLines from '../../shared/AddressLines'
import { AddressPropTypes } from '../../../shared/prop-types'

const AddressBlock = ({
  address,
  checked,
  civility,
  city,
  company,
  complement,
  country,
  countryCode,
  id,
  kind,
  firstName,
  lastName,
  mobilePhone,
  zipCode,
  onChange,
  onUpdate,
}) => {
  const inputId = `${kind}-address-${id}`
  const inputName = `order[${kind}_address_id]`

  return (
    <p className='o-layout__item o-layout__item-3-cols c-address c-address--box'>
      <input
        checked={checked}
        id={inputId}
        name={inputName}
        onChange={onChange}
        type='radio'
        value={id}
      />
      <label
        className='c-label c-label--radio c-label--highlight'
        htmlFor={inputId}
      >
        <AddressLines
          address={address}
          city={city}
          civility={civility}
          company={company}
          complement={complement}
          country={country}
          firstName={firstName}
          lastName={lastName}
          mobilePhone={mobilePhone}
          zipCode={zipCode}
        />
      </label>
      <a
        href='#'
        onClick={handleUpdate}
        style={{ display: 'block', textAlign: 'right' }}
      >
        Modifier
      </a>
    </p>
  )

  function handleUpdate(event) {
    event.preventDefault()
    onUpdate({
      address,
      city,
      company,
      complement,
      country,
      countryCode,
      id,
      firstName,
      lastName,
      mobilePhone,
      zipCode,
    })
  }
}

AddressBlock.propTypes = {
  ...AddressPropTypes,
  checked: bool.isRequired,
  kind: oneOf(['billing', 'shipping']).isRequired,
  onChange: func.isRequired,
  onUpdate: func.isRequired,
}

export default AddressBlock
