import Catalog from './Catalog'
import mountReactContainer from '../../../../shared/js/react-mounting'

document.addEventListener('DOMContentLoaded', () => {
  // Load related products as HTML for "add to cart" dialog display since we
  // don’t want to rebuild associated widget component in JS.
  const resultsHTML = document.getElementById('js-search-results')?.innerHTML

  mountReactContainer({
    id: 'js-catalog',
    component: Catalog,
    resultsHTML,
  })
})
