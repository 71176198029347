import Magnifier from 'react-image-magnify'
import { string } from 'prop-types'

import { crunchSrcSet } from './utils'
import { PhotoPropType } from './Photo'

const ZoomableImage = ({ alt, className, sizes, src, srcSet }) => {
  const maxUsefulSize = screen.width * 2
  const availableSizes = srcSet.map(([_, desc]) => parseInt(desc, 10))
  const maxSize =
    availableSizes.find((size) => size >= maxUsefulSize) ||
    availableSizes[availableSizes.length - 1]
  srcSet = crunchSrcSet(srcSet)

  const smallImage = {
    alt,
    isFluidWidth: true,
    sizes,
    src,
    srcSet,
  }

  const largeImage = {
    alt,
    height: maxSize,
    src,
    srcSet,
    width: maxSize,
  }

  return (
    <Magnifier
      className={className}
      enlargedImagePosition='over'
      enlargedImageStyle={{ maxWidth: 'none' }}
      fadeDurationInMs={200}
      hoverDelayInMs={200}
      pressDuration={200}
      largeImage={largeImage}
      shouldHideHintAfterFirstActivation={false}
      smallImage={smallImage}
    />
  )
}
ZoomableImage.propTypes = {
  alt: string,
  className: string,
  ...PhotoPropType,
}

export default ZoomableImage
