import { bool, func, string } from 'prop-types'
import { useState } from 'react'

import PickupPointsMap from './PickupPointsMap'
import { PickupPointsPropTypes } from '../../../shared/prop-types'

const PickupPoints = ({
  onCancel,
  onChange,
  pickupPoints,
  selectedCode,
  validateOnSelect = false,
}) => {
  const [displayHours, setDisplayHours] = useState(null)
  const [selectedTab, setSelectedTab] = useState('list')
  const { code, structuredAddress } =
    pickupPoints.find(({ code }) => code === selectedCode) || {}
  const [selectedPoint, setSelectedPoint] = useState({
    code,
    structuredAddress,
  })
  // Prefix IDs to prevent IDs conflicts, therefore unexpected behaviors
  const cssPrefix = validateOnSelect ? 'modal-' : ''

  return (
    <div className='c-relay-point'>
      <div className='c-tabs c-tabs--relay-point tabs' data-existing-hx='h2'>
        <ul
          className='o-layout c-tablist o-layout--center-x o-layout--keep@us u-hidden@fm tabs__list'
          role='tablist'
        >
          <li className='c-tablist__item tabs__item' role='presentation'>
            <a
              className='c-tablist__link tabs__link'
              id={`${cssPrefix}label_relay-point-list`}
              role='tab'
              aria-controls={`${cssPrefix}relay-point-list`}
              tabIndex={selectedTab === 'list' ? 0 : -1}
              aria-selected={selectedTab === 'list'}
              onClick={(event) => handleTabSelect(event, 'list')}
            >
              liste
            </a>
          </li>
          <li className='c-tablist__item tabs__item' role='presentation'>
            <a
              className='c-tablist__link tabs__link'
              id={`${cssPrefix}label_relay-point-map`}
              role='tab'
              aria-controls={`${cssPrefix}relay-point-map`}
              tabIndex={selectedTab === 'map' ? 0 : -1}
              aria-selected={selectedTab === 'map'}
              onClick={(event) => handleTabSelect(event, 'map')}
            >
              carte
            </a>
          </li>
        </ul>
        <div
          id={`${cssPrefix}relay-point-list`}
          className='c-tabpanel__list c-tabpanel'
          role='tabpanel'
          aria-labelledby={`${cssPrefix}label_relay-point-list`}
          aria-hidden={selectedTab !== 'list'}
        >
          <ul className='c-relay-point__list'>
            {pickupPoints.map(
              ({
                accessible,
                address,
                availabilityLabel,
                code,
                daysOffLabel,
                distance,
                kind,
                name,
                openingHours,
                structuredAddress,
              }) => (
                <li className='o-layout c-relay-point__item' key={name}>
                  <div className='o-layout__item'>
                    <p className='c-address c-address--light'>
                      <span className='c-address__name'>{name}</span>
                      <br />
                      {address}
                      {distance && (
                        <>
                          <br />
                          (à environ {distance} de votre adresse de livraison)
                        </>
                      )}
                      {accessible && (
                        <>
                          <br />
                          <mark>
                            Accessible aux personnes à mobilité réduite
                          </mark>
                        </>
                      )}
                    </p>
                    <button
                      className='c-disclosure c-disclosure--opening-hour'
                      aria-controls={`${cssPrefix}disclosure-opening-hour-${code}`}
                      aria-expanded='false'
                      onClick={(event) => toggleDisplayHours(event, code)}
                    >
                      Horaires d’ouverture
                    </button>
                    <div
                      id={`${cssPrefix}disclosure-opening-hour-${code}`}
                      className='c-relay-point__oh-list'
                      hidden={displayHours !== code}
                    >
                      {availabilityLabel && <small>{availabilityLabel}</small>}
                      {daysOffLabel && <small>{daysOffLabel}</small>}
                      <ul>
                        {openingHours.map(({ dow, start, end }) => (
                          <li key={`${code}-${dow}`}>
                            <span className='c-relay-point__oh-day'>{dow}</span>
                            <time className='c-relay-point__oh-time'>
                              {start}
                            </time>
                            –
                            <time className='c-relay-point__oh-time'>
                              {end}
                            </time>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className='o-layout__item o-layout__no-shrink'>
                    <input
                      checked={code === selectedCode}
                      id={`${cssPrefix}point-${code}`}
                      onChange={() => handleChange({ code, structuredAddress })}
                      type='radio'
                      value={code}
                      name='pickup_point'
                    />
                    <label
                      className='c-btn c-btn--secondary'
                      htmlFor={`${cssPrefix}point-${code}`}
                    >
                      Choisir ce point relais
                    </label>
                  </div>
                </li>
              )
            )}
          </ul>
        </div>
        <div
          id={`${cssPrefix}relay-point-map`}
          className='c-tabpanel__map c-tabpanel'
          aria-hidden={selectedTab !== 'map'}
          role='tabpanel'
          aria-labelledby={`${cssPrefix}label_relay-point-map`}
        >
          <div className='c-relay-point__map-wrapper'>
            <PickupPointsMap
              // Force component invalidation on tab change (for proper map size)
              key={selectedTab}
              markers={pickupPoints}
              onSelect={handleChange}
              selectedCode={selectedPoint.code}
            />
          </div>
        </div>
      </div>
      <div className='right-action-modal__btn u-hidden@fs'>
        <button className='c-btn c-btn--fill' type='reset' onClick={onCancel}>
          Annuler
        </button>
        <button
          className='c-btn c-btn--primary'
          type='submit'
          onClick={handleSubmit}
        >
          Appliquer
        </button>
      </div>
    </div>
  )

  // When on large devices, automatically send `onChange` callback.
  // Otherwise, wait for manual validation (submit action).
  function handleChange({ code, structuredAddress }) {
    setSelectedPoint({ code, structuredAddress })
    if (!validateOnSelect) {
      onChange({ code, structuredAddress })
    }
  }

  // Send stored data for selected point
  function handleSubmit() {
    onChange(selectedPoint)
  }

  function handleTabSelect(event, tab) {
    event.preventDefault()
    setSelectedTab(tab)
  }

  function toggleDisplayHours(event, code) {
    event.preventDefault()
    setDisplayHours(displayHours === code ? null : code)
  }
}

PickupPoints.propTypes = {
  onCancel: func,
  onChange: func.isRequired,
  pickupPoints: PickupPointsPropTypes.isRequired,
  selectedCode: string,
  validateOnSelect: bool,
}

export default PickupPoints
