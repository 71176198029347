import { func, oneOf, shape, string } from 'prop-types'
import { useState } from 'react'

import {
  AddressPropTypes,
  CountriesPropTypes,
} from '../../../shared/prop-types'
import { camelToSnakeCaseObjectKeys } from '../../../../../shared/js/formatters'
import { patch, post } from '../../../../../shared/js/json-fetch'

const AddressForm = ({
  address: initialAddress,
  civility,
  countries,
  firstName,
  lastName,
  kind,
  onCancel,
  onSubmit,
  url,
}) => {
  const [address, setAddress] = useState({
    address: '',
    city: '',
    civility,
    complement: '',
    countryCode: 'FR',
    firstName,
    lastName,
    mobilePhone: '',
    zipCode: '',
    ...initialAddress,
  })
  const [disabled, setDisabled] = useState(!validateAddress(address))
  const [error, setError] = useState('')

  return (
    <>
      <div className='o-layout o-layout--wrap o-container-6 u-mb-4'>
        <div className='o-layout__item o-layout__item--third u-mb-2@us'>
          <label htmlFor='civility'>Civilité</label>
          <select
            autoComplete={`${kind} honorific-prefix`}
            className='c-select '
            id='civility'
            name='civility'
            onChange={handleChange}
            value={address.civility}
          >
            <option value='mr'>Monsieur</option>
            <option value='mrs'>Madame</option>
          </select>
        </div>

        <div className='o-layout__item o-layout__item--third u-mb-2'>
          <label htmlFor='name'>Nom</label>
          <input
            aria-required='true'
            autoComplete={`${kind} family-name`}
            id='name'
            name='lastName'
            onChange={handleChange}
            type='text'
            value={address.lastName}
          />
        </div>

        <div className='o-layout__item o-layout__item--third u-mb-2'>
          <label htmlFor='firstname'>Prénom</label>
          <input
            aria-required='true'
            autoComplete={`${kind} given-name`}
            id='firstname'
            name='firstName'
            onChange={handleChange}
            type='text'
            value={address.firstName}
          />
        </div>

        <div className='o-layout__item o-layout__item--full u-mb-2'>
          <label htmlFor='address'>Adresse</label>
          <input
            aria-required='true'
            autoComplete={`${kind} address-line1`}
            id='address'
            name='address'
            onChange={handleChange}
            type='text'
            value={address.address}
          />
        </div>

        <div className='o-layout__item o-layout__item--full u-mb-2'>
          <label htmlFor='complement'>Complément</label>
          <input
            aria-required='true'
            autoComplete={`${kind} address-line2`}
            id='complement'
            name='complement'
            onChange={handleChange}
            type='text'
            value={address.complement}
          />
        </div>

        <div className='o-layout__item o-layout__item--half u-mb-2'>
          <label htmlFor='zipCode'>Code postal</label>
          <input
            aria-required='true'
            autoComplete={`${kind} postal-code`}
            id='zipCode'
            inputMode='numeric'
            name='zipCode'
            onChange={handleChange}
            type='text'
            value={address.zipCode}
          />
        </div>

        <div className='o-layout__item o-layout__item--half u-mb-2'>
          <label htmlFor='city'>Ville</label>
          <input
            aria-required='true'
            autoComplete={`${kind} address-level2`}
            id='city'
            name='city'
            onChange={handleChange}
            type='text'
            value={address.city}
          />
        </div>

        <div className='o-layout__item o-layout__item--half u-mb-2@us'>
          <label htmlFor='country'>Pays</label>
          <select
            autoComplete={`${kind} country`}
            className='c-select '
            id='country'
            name='countryCode'
            onChange={handleChange}
            value={address.countryCode}
          >
            {countries.map(({ code, name }) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <div className='o-layout__item o-layout__item--half'>
          <label htmlFor='phone'>Numéro de téléphone</label>
          <input
            aria-required='true'
            autoComplete={`${kind} tel-national`}
            id='mobilePhone'
            inputMode='tel'
            name='mobilePhone'
            onChange={handleChange}
            type='text'
            value={address.mobilePhone}
          />
        </div>
      </div>
      {error && (
        <div className={'c-message c-message--error u-mb-2'}>{error}</div>
      )}
      <div className='o-layout__item o-layout__item--full u-txt-center'>
        <button
          onClick={handleSubmit}
          className='c-btn c-btn--primary'
          type='button'
          disabled={disabled}
        >
          {initialAddress ? 'Modifier' : 'Ajouter'} cette adresse
        </button>
        {onCancel && (
          <button
            onClick={onCancel}
            className='c-btn c-btn--secondary'
            type='button'
          >
            {initialAddress
              ? 'Annuler la modification'
              : 'Choisir plutôt une adresse existante'}
          </button>
        )}
      </div>
    </>
  )

  function handleChange({ target: { name, value } }) {
    const newAddress = {
      ...address,
      [name]: value,
    }
    setAddress(newAddress)
    // Restore submit button, remove error
    setDisabled(!validateAddress(newAddress))

    if (error) {
      setError('')
    }
  }

  // Send data to server, then update list and select created address
  async function handleSubmit(event) {
    event.preventDefault()
    setDisabled(!disabled)

    const { data, error, flash } = await send(url, address)
    if (error) {
      setError(flash.message)
    } else {
      const { id } = data
      // setDisabled(!disabled)
      onSubmit({ ...address, id }, kind)
    }
  }

  // Send data using best suited method (update = path / create = post) Extract
  // `country` since it must not be sent to the server (`countryCode` is sent
  // instead).
  async function send(url, { country, ...values }) {
    const address = camelToSnakeCaseObjectKeys(values)
    if (address.id === undefined) {
      return await post(url, { address })
    }
    return await patch(`${url}/${address.id}`, { address })
  }

  function validateAddress(address) {
    return MANDATORY_FIELDS.every((field) => address[field].trim().length > 0)
  }
}

const MANDATORY_FIELDS = [
  'civility',
  'firstName',
  'lastName',
  'address',
  'city',
  'zipCode',
  'mobilePhone',
]

AddressForm.propTypes = {
  address: shape(AddressPropTypes),
  countries: CountriesPropTypes,
  civility: string.isRequired,
  firstName: string.isRequired,
  lastName: string.isRequired,
  kind: oneOf(['billing', 'shipping']).isRequired,
  onCancel: func,
  onSubmit: func.isRequired,
  url: string.isRequired,
}

export default AddressForm
