import { arrayOf, func, number, string } from 'prop-types'
import { useState } from 'react'

import { formatPrice } from '../../../../../shared/js/formatters'
import { post as jsonPost } from '../../../../../shared/js/json-fetch'
import useTimeout from '../../../../../shared/js/use-timeout'

const LoyaltyForm = ({
  ceilingCents,
  onChange,
  post = jsonPost,
  slices = [],
  url,
}) => {
  const [loyaltyHidden, setLoyaltyHidden] = useState(true)
  const [flash, setFlash] = useState(null)
  const [disabled, setDisabled] = useState(false)

  // Remove flash messages after 5 seconds
  useTimeout(() => setFlash(null), 5000)

  // Skip rendering when there is no slices/points to use
  if (ceilingCents <= 0 || slices.length === 0) {
    return null
  }

  // Slices contains every possible slices for current customer points in order
  // to manage ceilingCents changes (customer changing line items quantities,
  // applied discounts…)
  const filteredSlices = slices.filter(
    ([slicePoints, sliceCents]) => sliceCents <= ceilingCents
  )

  // Skip rendering if there is no matching slice
  if (filteredSlices.length === 0) {
    return null
  }

  const [bestPoints, bestCents] = filteredSlices[filteredSlices.length - 1]

  return (
    <div className='c-voucher'>
      <button
        className='c-disclosure c-disclosure--voucher'
        aria-controls='disclosure-discount'
        aria-expanded={loyaltyHidden}
        onClick={toggleDiscount}
        type='button'
      >
        Points de fidélité
      </button>
      <div
        id='disclosure-discount'
        className='c-voucher__content'
        hidden={loyaltyHidden}
      >
        <p className='u-mb-2'>
          Vous avez <strong>{formatPoints(bestPoints)}</strong> points de
          fidélité qui vous donnent accès à{' '}
          <strong>{formatPrice(bestCents)}</strong> de remise sur votre commande
        </p>
        <button
          className='c-btn c-btn--primary'
          onClick={applyLoyalties}
          disabled={disabled}
        >
          Utiliser mes points
        </button>
      </div>
      {flash && (
        <div className={`c-message c-message--${flash.kind}`}>
          {flash.message}
        </div>
      )}
    </div>
  )

  // Request server then print applied discount
  // or error message when not applicatble
  async function applyLoyalties(event) {
    event.preventDefault()
    setDisabled(true)
    const { data, flash } = await post(url, {
      loyalty_points_used: bestPoints,
    })
    setDisabled(false)
    if (flash) {
      setFlash(flash)
    }
    if (data) {
      onChange(data)
    }
  }

  function toggleDiscount() {
    setLoyaltyHidden(!loyaltyHidden)
  }
}

LoyaltyForm.propTypes = {
  ceilingCents: number.isRequired,
  onChange: func.isRequired,
  slices: arrayOf(arrayOf(number)),
  url: string.isRequired,
  // This one is for testing purpose.  Post function is injected from json-fetch
  // module using default params
  post: func,
}

const pointsFormatter = new Intl.NumberFormat('fr-FR')

function formatPoints(points) {
  return pointsFormatter.format(points)
}

export default LoyaltyForm
