import { useEffect, useRef } from 'react'

export default function useTimeout(callback, delay) {
  // For details on the rationale, see
  // https://overreacted.io/fr/making-setinterval-declarative-with-react-hooks/
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (delay != null) {
      const timer = setTimeout(() => savedCallback.current(), delay)
      return () => clearTimeout(timer)
    }
  }, [delay])
}
