import { any, bool, func, string } from 'prop-types'
import { useState } from 'react'

import {
  AddressPropTypes,
  DiscountsPropTypes,
  LineItemSimplifiedPropTypes,
  PickupPointPropTypes,
  PriceDetailsBaseProps,
} from '../../../shared/prop-types'
import { formatPrice } from '../../../../../shared/js/formatters'
import imgAlma from '../../../../static/logo/alma.png'
import imgAmex from '../../../../static/logo/amex.svg'
import imgCard from '../../../../static/logo/cb.svg'
// import imgCheck from '../../../../static/logo/paiement-cheque.png'
import imgMastercard from '../../../../static/logo/mastercard.svg'
// import imgPaypal from '../../../../static/logo/paypal.svg'
import imgVisa from '../../../../static/logo/visa.svg'
import imgWire from '../../../../static/logo/virement-bancaire.png'

const LOGOS = [
  {
    width: 66,
    height: 36,
    src: imgAlma,
    alt: 'Alma paiement en 3 fois',
  },
  {
    width: 62,
    height: 30,
    src: imgVisa,
    alt: 'Visa',
  },
  {
    width: 50,
    height: 30,
    src: imgMastercard,
    alt: 'Mastercard',
  },
  {
    width: 45,
    height: 30,
    src: imgCard,
    alt: 'Cartes Bancaires',
  },
  // {
  //   width: 75,
  //   height: 30,
  //   src: imgPaypal,
  //   alt: 'Paypal',
  // },
  {
    width: 48,
    height: 30,
    src: imgAmex,
    alt: 'American Express',
  },
  // {
  //   width: 75,
  //   height: 36,
  //   src: imgCheck,
  //   alt: 'Paiement par chèque',
  // },
  {
    width: 75,
    height: 36,
    src: imgWire,
    alt: 'Virement bancaire',
  },
]

const PriceDetails = ({
  actualShippingAddress,
  children,
  cents,
  deliveryLabel,
  discounts,
  lineItems,
  nextStepActionLabel,
  nextStepURL,
  onDiscountDelete,
  selectedPickupPoint,
  shipping,
  sticky,
  taxCents,
  totalCents,
}) => {
  // Hide when on sticky mode on small device
  const [visible, setVisible] = useState(checkFirstVisibility())
  const [detailsVisible, setDetailsVisible] = useState(false)

  const formattedTotalCents = formatPrice(totalCents)

  // Customize CSS classes on sticky mode
  let containerClass = 'c-basket-summary'
  let logosClasses = 'c-payment-mode c-payment-mode--cart'

  // Change display on sticky mode
  if (sticky) {
    containerClass = containerClass + ' c-basket-summary--sticky'
    logosClasses = logosClasses + ' u-hidden@us'
  }

  return (
    <div className={containerClass}>
      {sticky && (
        <button
          className='c-disclosure c-disclosure--basket-summary'
          aria-controls='disclosure-basket-summary'
          aria-expanded={visible}
          onClick={toggleVisibility}
          type='button'
        >
          <span className='c-basket-summary__label'>Récapitulatif</span>
          <span className='c-basket-summary__amount'>
            {formattedTotalCents}
          </span>
        </button>
      )}
      {visible && (
        <>
          <div className='c-cart-total' id='disclosure-basket-summary'>
            <table className='c-cart-total__table'>
              <caption className='c-cart-total__title'>Total</caption>
              <tfoot className='c-cart-total__footer'>
                <tr>
                  <th scope='row'>Total</th>
                  <td
                    className='c-cart-total__amount'
                    data-testid='total-amount'
                  >
                    {formattedTotalCents}
                  </td>
                </tr>
              </tfoot>
              <tbody>
                <tr>
                  <th scope='row'>
                    <span className='u-block'>
                      Panier <abbr title='Hors Taxes'>HT</abbr>
                    </span>
                    {lineItems && (
                      <button
                        className='c-disclosure c-disclosure--cart'
                        aria-controls='c-cart-total__article'
                        aria-expanded={detailsVisible}
                        onClick={toggleDetails}
                        type='button'
                      >
                        Voir le détail
                      </button>
                    )}
                  </th>
                  <td
                    className='c-cart-total__amount'
                    data-testid='total-amount-ht'
                  >
                    {formatPrice(cents)}
                  </td>
                </tr>
                <LineItemsRow lineItems={lineItems} hidden={!detailsVisible} />
                <tr>
                  <th scope='row'>
                    <abbr>TVA</abbr>
                  </th>
                  <td className='c-cart-total__amount' data-testid='total-vat'>
                    {formatPrice(taxCents)}
                  </td>
                </tr>
                <DiscountsRow
                  discounts={discounts}
                  onDiscountDelete={onDiscountDelete}
                />
                {shipping?.name && (
                  <tr>
                    <th className='c-cart-total__delivery-method' scope='row'>
                      {shipping.name}
                    </th>
                    <td
                      className={`c-cart-total__delivery-cost${
                        shipping.cents === 0 ? ' free' : ''
                      }`}
                    >
                      {shipping.cents > 0
                        ? formatPrice(shipping.cents)
                        : 'Gratuit'}
                    </td>
                  </tr>
                )}
                {actualShippingAddress && (
                  <tr className='c-cart-total__delivery-address'>
                    <th className='c-cart-total__hidden' scope='row'>
                      <span>Adresse de livraison</span>
                    </th>
                    <td>
                      {selectedPickupPoint ? (
                        <PickupPointAddress {...selectedPickupPoint} />
                      ) : (
                        <Address {...actualShippingAddress} />
                      )}
                    </td>
                  </tr>
                )}
                {deliveryLabel && (
                  <tr className='c-cart-total__delivery-date'>
                    <th className='c-cart-total__hidden' scope='row'>
                      <span>Date de livraison</span>
                    </th>
                    <td>
                      <mark>{deliveryLabel}</mark>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {nextStepActionLabel && (
              <div className='c-cart-total__order'>
                <a className='c-btn c-btn--primary' href={nextStepURL}>
                  {nextStepActionLabel}
                </a>
              </div>
            )}
          </div>
          {children}
          <div className={logosClasses}>
            <strong className='c-payment-mode__title'>Paiement sécurisé</strong>
            <small>Protocol SSL/Protection des données</small>
            <ul className='c-payment-mode__list'>
              {LOGOS.map((logo) => (
                <li className='c-payment-mode__item' key={logo.alt}>
                  <img className='c-payment-mode__logo' {...logo} />
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  )

  // Hide when on sticky mode on small device
  function checkFirstVisibility() {
    if (sticky && 'matchMedia' in window) {
      return window.matchMedia('(min-width: 49.99em)').matches
    }
    return true
  }

  function toggleVisibility(event) {
    event.preventDefault()
    setVisible(!visible)
  }

  function toggleDetails(event) {
    event.preventDefault()
    setDetailsVisible(!detailsVisible)
  }
}

PriceDetails.propTypes = {
  ...PriceDetailsBaseProps,
  children: any,
  extraClassName: string,
  nextStepActionLabel: string,
  onDiscountDelete: func,
  sticky: bool,
}

export default PriceDetails

const Address = ({
  address,
  city,
  company,
  complement,
  country,
  firstName,
  lastName,
  zipCode,
}) => {
  return (
    <p className='c-address'>
      <strong>
        {firstName} {lastName}
      </strong>
      {company && (
        <>
          <br />
          {company}
        </>
      )}
      <br />
      {address}
      {complement && (
        <>
          <br />
          {complement}
        </>
      )}
      <br />
      {zipCode} {city}, {country}
    </p>
  )
}

Address.propTypes = {
  ...AddressPropTypes,
}

const PickupPointAddress = ({ address, name }) => {
  return (
    <p className='c-address'>
      <strong>{name}</strong>
      <br />
      {address}
    </p>
  )
}

PickupPointAddress.propTypes = {
  ...PickupPointPropTypes,
}

// Manage line items display with show/hide options for details
const LineItemsRow = ({ hidden, lineItems = [] }) => {
  if (lineItems.length === 0) {
    return null
  }

  return (
    <tr id='c-cart-total__article' hidden={hidden}>
      <th className='c-cart-total__hidden' scope='row'>
        <span>Articles</span>
      </th>
      <td className='c-cart-total__article-detail'>
        {lineItems.map(({ id, name, quantifiedCents }) => (
          <span className='o-table' key={id}>
            <span className='o-table__cell c-cart-total__article-name'>
              {name}
            </span>
            <span className='o-table__cell c-cart-total__article-price'>
              {formatPrice(quantifiedCents)}
            </span>
          </span>
        ))}
      </td>
    </tr>
  )
}

LineItemsRow.propTypes = {
  hidden: bool,
  lineItems: LineItemSimplifiedPropTypes,
}

// Manage discounts display with show/hide options for details
const DiscountsRow = ({ discounts = [], onDiscountDelete }) => {
  const [hidden, setHidden] = useState(true)

  if (discounts.length === 0) {
    return null
  }

  const totalDiscountCents = discounts.reduce(
    (total, { cents }) => total + cents,
    0
  )

  return (
    <>
      <tr>
        <th scope='row'>
          <span className='u-block'>Réductions</span>
          {discounts && (
            <button
              className='c-disclosure c-disclosure--discounts'
              aria-controls='c-cart-total__discounts'
              aria-expanded={!hidden}
              onClick={toggleDetails}
              type='button'
            >
              Voir le détail
            </button>
          )}
        </th>
        <td className='c-cart-total__amount'>
          −{formatPrice(totalDiscountCents)}
        </td>
      </tr>

      <tr id='c-cart-total__discounts' hidden={hidden}>
        <th className='c-cart-total__hidden' scope='row'>
          <span>Réductions</span>
        </th>
        <td className='c-cart-total__article-detail'>
          {discounts.map(({ id, name, cents }) => (
            <span className='o-table' key={id}>
              <span className='o-table__cell c-cart-total__article-name'>
                {cents > 0 && onDiscountDelete ? (
                  <a
                    href='#delete-discount'
                    onClick={(e) => handleDiscountDeletion(e, id)}
                    className='c-tag c-tag--remove'
                    title={`Supprimer la réduction « ${name} »`}
                    data-testid={`discount-${id}-removal`}
                  >
                    {name}
                  </a>
                ) : (
                  <span className='c-tag'>{name}</span>
                )}
              </span>
              <span
                className='o-table__cell c-cart-total__article-price'
                data-testid={`discount-price-${id}`}
              >
                {cents > 0 ? `−${formatPrice(cents)}` : 'Gratuit'}
              </span>
            </span>
          ))}
        </td>
      </tr>
    </>
  )
  function handleDiscountDeletion(e, id) {
    e.preventDefault()
    onDiscountDelete(id)
  }

  function toggleDetails(event) {
    event.preventDefault()
    setHidden(!hidden)
  }
}

DiscountsRow.propTypes = {
  onDiscountDelete: func,
  discounts: DiscountsPropTypes,
}
