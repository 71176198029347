import { arrayOf, bool, func, number, string } from 'prop-types'
import { useState } from 'react'

import Slider from '@material-ui/core/Slider'
import withStyles from '@material-ui/core/styles/withStyles'

const StyledSlider = withStyles({
  root: {
    color: '#ba8d26',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  mark: { backgroundColor: 'transparent' },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider)

// Button is only displayed when not in a modal.
// On mobile, change is directly triggered.
export default function RangeSlider({
  id,
  min,
  max,
  modal,
  onChange,
  value: initialValue,
}) {
  const [value, setValue] = useState(initialValue)
  const unchanged = value[0] === initialValue[0] && value[1] === initialValue[1]

  return (
    <div className='c-filter__range'>
      <StyledSlider
        min={min}
        max={max}
        value={value}
        onChange={pendingChange}
        valueLabelDisplay='on'
        aria-labelledby='range-slider'
        getAriaValueText={(val) => formatText(val[0])}
        valueLabelFormat={formatText}
      />
      {!modal && (
        <button
          className='c-btn c-btn--secondary c-btn--small u-hidden@us'
          onClick={validateChange}
          disabled={unchanged}
        >
          Valider
        </button>
      )}
    </div>
  )

  function formatText(value) {
    return `${value}€`
  }

  function validateChange(event) {
    event.preventDefault()
    handleChange({ value })
  }

  function handleChange({ value }) {
    onChange({
      label: `de ${value[0]}€ à ${value[1]}€`,
      value,
    })
  }

  // Change value without triggering search
  function pendingChange(event, value) {
    setValue(value)

    if (modal) {
      handleChange({ value })
    }
  }
}

RangeSlider.propTypes = {
  id: string.isRequired,
  max: number.isRequired,
  min: number.isRequired,
  modal: bool.isRequired,
  onChange: func.isRequired,
  value: arrayOf(number).isRequired,
}
