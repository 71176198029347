import { capitalize, formatPrice } from './formatters'

export function computeOptionsCents(options = []) {
  if (!options.length) {
    return { optionsCents: 0, optionsTaxCents: 0 }
  }
  return options.reduce(reduceOptionCents, {
    optionsCents: 0,
    optionsTaxCents: 0,
  })
}

export function computeOptionsToParams(options, params = {}) {
  for (const { id, kind, values } of options) {
    if (kind === 'boolean') {
      const value = values.find(({ selected }) => selected)
      if (value) {
        params[id] = value.id
        if (value.options?.length > 0) {
          computeOptionsToParams(value.options, params)
        }
      }
    } else if (kind === 'selection') {
      let selectedValues = values.filter(({ selected }) => selected)
      selectedValues = selectedValues.length > 0 ? selectedValues : [values[0]]
      for (const { id: valueId } of selectedValues) {
        params[id] = valueId
      }
    } else if (kind === 'literal') {
      const value = values.find(({ selected }) => selected) || values[0]
      if (value) {
        params[id] = value.text
      }
    }
  }

  return params
}

export function computeOptionValidity({ id, kind, required, values }) {
  // When field is not required, their is no need to check
  if (!required) {
    return true
  }

  if (kind === 'literal') {
    // Expect value to be filled
    const value = values.find(({ selected }) => selected) || values[0]
    return Boolean(value?.text)
  } else if (kind === 'selection') {
    // Expect at least one value to be selected
    return values.some(({ selected }) => selected)
  }
}

// Checks if all required options are set.  Only first level options are checked
// here.  We expect each suboptions group to be validated by their direct
// parent.  Returns a global feedback as a "valid" boolean and a field by field
// feedback.
export function computeOptionsValidity(options) {
  // DEV NOTE: every(…) returns `true` on empty arrays, which matches our need.
  return options.every(computeOptionValidity)
}

const REGEX_CTA_PREFIX = /^\s*(?:Ajoute[rz]|Clique[rz](?: ici)? pour)/i

// Remove label "action" prefix/verb and money suffix
export function formatOptionActionLabel(
  label,
  { cents = 0, taxCents = 0, updateMode = false } = {}
) {
  const totalCents = cents + taxCents
  let ctaLabel =
    !updateMode && totalCents > 0
      ? `${label} (+${formatPrice(totalCents, { stripUnit: true })})`
      : label
  if (updateMode) {
    ctaLabel = ctaLabel
      .replace(REGEX_CTA_PREFIX, 'Modifier')
      .replace(/une?/i, (word) => (word.toLowerCase() === 'un' ? 'le' : 'la'))
  }
  return ctaLabel
}

const ACTION_CRUFT =
  /(?:Choisi(?:sse)?|Ajoute|Sélectionne)[rz]? (?:le|la|votre|une?)/
const CLICK_CRUFT = /Clique[rz](?: ici)? pour l[ea]/
const PREFIX_CRUFT = new RegExp(
  `^\\s*(?:${ACTION_CRUFT.source}|${CLICK_CRUFT.source})\\s+`
)
const COST_CRUFT = /\s*\(\+\d+\s+€\)/
const REGEX_OPTION_LABEL_CRUFT = new RegExp(
  `${PREFIX_CRUFT.source}|${COST_CRUFT.source}`,
  'gi'
)

// Remove label "action" prefix/verb and money suffix
export function formatOptionLabel(label) {
  return label.replace(REGEX_OPTION_LABEL_CRUFT, '').replace(/\s*:/g, '')
}

export function formatOptionsSummary(
  options = [],
  { comparison = false } = {}
) {
  return options
    .map((option) => formatOptionSummary(option, comparison))
    .filter(Boolean)
}

export function formatOptionSummary(
  { kind, label, values },
  comparison = false
) {
  const title = formatOptionLabel(label)
  const labels = formatOptionValues(kind, values, comparison)

  if (!labels.length) {
    return null
  }

  // Detect title and associated values and title with suboptions (i.e. when
  // labels is an array of arrays).
  // Kind is preserved for options comparison (after BO updates).
  if (labels.length === 1) {
    const label = labels[0]
    if (!label) {
      return [capitalize(title)]
    }

    const result = [capitalize(title), label]
    // Kind is only injected on comparison mode (ie comparing updated options
    // with their previous values).
    if (comparison) {
      result.push(kind)
    }

    return result
  }

  // Multiple labels
  if (typeof labels[0] === 'string') {
    const result = [capitalize(title), labels.join(', ')]
    // Kind is only injected on comparison mode (ie comparing updated options
    // with their previous values).
    if (comparison) {
      result.push(kind)
    }

    return result
  }

  const result = [capitalize(title), labels]

  // Kind is only injected on comparison mode (ie comparing updated options
  // with their previous values).
  if (comparison) {
    result.push(kind)
  }

  return result
}

export const REGEX_BASE64_IMAGE = /^data:image\/(png|jpg|jpeg);base64,/i

function formatOptionValues(kind, values, comparison) {
  const result = []
  for (const {
    label: valueLabel = '',
    options: valueOptions = [],
    selected,
    text,
  } of values) {
    if (!selected) continue

    if (kind === 'literal' && !REGEX_BASE64_IMAGE.test(text)) {
      result.push(text || '')
    } else if (kind === 'selection') {
      result.push(valueLabel.toLowerCase())
    } else if (valueOptions.length === 0) {
      result.push('')
    }
    if (valueOptions.length > 0) {
      const optionsLabels = formatOptionsSummary(valueOptions, { comparison })
      result.push(...optionsLabels)
    }
  }
  return result
}

// Only keeps labels that differ from the next ones (ie updated options labels)
export function mapLabelsDiffs(labels, nextLabels) {
  return labels
    .map((entry) => {
      const label = entry[1]
      // Look for the same options to compare with based on its title
      // If no compared entry is found, it means option has been removed
      const comparedEntry = nextLabels.find(([title]) => title === entry[0])

      // Force compared label to null for later analysis (ie OptionSummary)
      const comparedLabel = comparedEntry?.[1] || null

      if (Array.isArray(label)) {
        const subLabels = nextLabels(label)
        return subLabels.some(Boolean) ? subLabels : false
      }

      // Add new label at the end of the array
      return comparedLabel !== label ? [...entry, comparedLabel] : false
    })
    .filter(Boolean)
}

// Reducer that sums sub-options cents
function sum(acc, { cents, taxCents, options = [], selected }) {
  if (!selected) {
    return acc
  }
  const { optionsCents: subCents, optionsTaxCents: subTaxCents } =
    computeOptionsCents(options)

  return {
    optionsCents: acc.optionsCents + cents + subCents,
    optionsTaxCents: acc.optionsTaxCents + taxCents + subTaxCents,
  }
}

export function reduceOptionCents(
  acc = { optionsCents: 0, optionsTaxCents: 0 },
  { values = [] }
) {
  return values.reduce(sum, acc)
}
