import { arrayOf, func, shape, string } from 'prop-types'

import { formatOptionsSummary } from '../options-utils'
import OptionsSummaryLabel from './OptionsSummaryLabel'
import { ProductOptionPropTypes } from '../prop-types'

const OptionsSummary = ({ onEdit, options = [], orderRef }) => {
  if (options.length === 0) {
    return null
  }

  const labels = formatOptionsSummary(options)
  const updateLabel =
    options.length > 1 ? '(modifier les options)' : '(modifier)'

  if (labels.length === 1) {
    const [title, label] = labels[0]

    return (
      <>
        <OptionsSummaryLabel label={label} title={title} />
        {onEdit && (
          <>
            &nbsp;
            <a href='#' onClick={onEdit}>
              {updateLabel}
            </a>
          </>
        )}
      </>
    )
  } else if (labels.length > 1) {
    return (
      <>
        {labels.map(([title, label], index) => (
          <div key={`${title}-${index}`}>
            <OptionsSummaryLabel label={label} title={title} />
          </div>
        ))}
        {onEdit && (
          <a href='#' onClick={onEdit}>
            {updateLabel}
          </a>
        )}
      </>
    )
  }

  // Do not ask for option creation when editing is forbidden
  if (!onEdit) {
    return null
  }

  const addLabel =
    options.length > 1 ? '(ajouter des options)' : '(ajouter une option)'

  return (
    <a href='#' onClick={onEdit}>
      {addLabel}
    </a>
  )
}

OptionsSummary.propTypes = {
  onEdit: func,
  options: arrayOf(shape(ProductOptionPropTypes)),
  orderRef: string,
}

export default OptionsSummary
