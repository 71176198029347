import { arrayOf, func, number, oneOf, shape, string } from 'prop-types'
import { useState } from 'react'

import AddressBlock from './AddressBlock'
import AddressForm from './AddressForm'
import {
  AddressPropTypes,
  CountriesPropTypes,
} from '../../../shared/prop-types'

const AddressesBlock = ({
  addAddressURL,
  addresses,
  countries,
  kind,
  onCreateOrUpdate,
  onSelect,
  selectedId,
  // Pre-fill customer infos on address creation
  civility,
  firstName,
  lastName,
}) => {
  const [addressToUpdate, setAddressToUpdate] = useState(null)
  const [newAddress, setNewAddress] = useState(false)

  const onCancel =
    addresses.length > 0
      ? addressToUpdate
        ? () => setAddressToUpdate(null)
        : handleNewAddress
      : undefined

  if (addresses.length === 0 || newAddress || addressToUpdate) {
    return (
      <AddressForm
        address={addressToUpdate}
        civility={civility}
        countries={countries}
        firstName={firstName}
        lastName={lastName}
        kind={kind}
        onSubmit={handleCreateOrUpdate}
        onCancel={onCancel}
        url={addAddressURL}
      />
    )
  }

  return (
    <div className='o-layout o-layout--wrap o-layout--start-x c-form__details'>
      {addresses.map((address) => (
        <AddressBlock
          {...address}
          key={`shipping-${address.id}`}
          checked={selectedId === address.id}
          country={
            countries.find(({ code }) => code === address.countryCode)?.name
          }
          kind={kind}
          onChange={handleSelect}
          onUpdate={handleUpdate}
        />
      ))}
      {!newAddress && (
        <button
          className='o-layout__item o-layout__item-3-cols c-btn c-btn--secondary'
          onClick={handleNewAddress}
        >
          Ajouter une nouvelle adresse
        </button>
      )}
    </div>
  )

  // Hide form, back to addresses blocks display
  function handleCreateOrUpdate(...args) {
    if (addressToUpdate) {
      setAddressToUpdate(null)
    } else {
      setNewAddress(false)
    }
    onCreateOrUpdate(...args)
  }

  function handleNewAddress() {
    // Empty actual billing address when waiting for new address creation
    if (!newAddress) {
      onSelect(undefined, kind)
    }
    setNewAddress(!newAddress)
  }

  function handleSelect({ target: { name, value } }) {
    onSelect(Number(value), kind)
  }

  function handleUpdate(address) {
    setAddressToUpdate(address)
  }
}

AddressesBlock.propTypes = {
  addAddressURL: string.isRequired,
  addresses: arrayOf(shape(AddressPropTypes)).isRequired,
  civility: string.isRequired,
  countries: CountriesPropTypes,
  firstName: string.isRequired,
  lastName: string.isRequired,
  kind: oneOf(['billing', 'shipping']).isRequired,
  onCreateOrUpdate: func.isRequired,
  onSelect: func.isRequired,
  selectedId: number,
}

export default AddressesBlock
