import { ApiMode, Widgets } from '@alma/widgets'
import { number, string } from 'prop-types'
import { useEffect } from 'react'

import './AlmaWidget.css'

const WIDGET_ID = 'alma-widget'

// TODO: use "useId" after React 18 upgrade
export default function AlmaWidget({ merchantId, cents }) {
  useEffect(() => {
    const mode = process.env.NODE_ENV === 'production' ? 'LIVE' : 'TEST'
    const widgets = Widgets.initialize(merchantId, ApiMode[mode])

    widgets.add(Widgets.PaymentPlans, {
      container: `#${WIDGET_ID}`,
      purchaseAmount: cents,
      locale: 'fr',
    })
  }, [merchantId, cents])

  return <div style={{ zoom: 0.9, maxWidth: 'max-content' }} id={WIDGET_ID} />
}
AlmaWidget.propTypes = {
  merchantId: string.isRequired,
  cents: number.isRequired,
}
