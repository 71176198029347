import { number } from 'prop-types'
import { useEffect, useState } from 'react'

const EVENT_NAME = 'cart:new-size'

// Cart item count badge for the site’s top-right header.
//
// This monitors a document-wide 'cart:increase' DOM custom event that provides
// added size info.
export default function Cart({ size: initialSize }) {
  const [size, setSize] = useState(initialSize)

  useEffect(() => {
    document.addEventListener(EVENT_NAME, updateSize)
    return () => {
      document.removeEventListener(EVENT_NAME, updateSize)
    }
  }, [])

  if (size === 0) {
    return <span className='u-visually-hidden'>aucun article</span>
  }

  return (
    <span className='c-shortcut__product'>
      {size}
      <span className='u-visually-hidden'>
        {' '}
        {size > 1 ? 'articles' : 'article'}
      </span>
    </span>
  )

  function updateSize({ detail: { size } }) {
    setSize(size)
  }
}

export function notifyCartBadge(size) {
  document.dispatchEvent(new CustomEvent(EVENT_NAME, { detail: { size } }))
}

Cart.propTypes = {
  size: number.isRequired,
}
