import { arrayOf, string } from 'prop-types'

import { crunchSrcSet } from './utils'
import ZoomableImage from './ZoomableImage'

export const PhotoPropType = {
  fallbackURL: string.isRequired,
  sizes: string.isRequired,
  srcset: arrayOf(arrayOf(string)).isRequired,
}

const ZOOMABLE_THRESHOLD = 900 // for now; ideally it'd be 1_000, but 50% prod images are 900x900 rn.

const Photo = ({ alt, fallbackURL, sizes, srcset }) => {
  const maxSize = parseInt(srcset[srcset.length - 1][1], 10)
  return maxSize < ZOOMABLE_THRESHOLD ? (
    <img
      alt={alt}
      className='c-slider__preview'
      sizes={sizes}
      src={fallbackURL}
      srcSet={crunchSrcSet(srcset)}
    />
  ) : (
    <ZoomableImage
      alt={alt}
      className='c-slider__preview'
      sizes={sizes}
      src={fallbackURL}
      srcSet={srcset}
    />
  )
}
Photo.propTypes = {
  alt: string,
  ...PhotoPropType,
}

export default Photo
