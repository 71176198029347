import Glide from '@glidejs/glide'

// Default configuration
// --------------------------------------------------------------------------
const defaultConfig = {
  classes: {
    direction: {
      ltr: 'c-carousel--ltr',
      rtl: 'c-carousel--rtl',
    },
    slider: 'c-carousel--slider',
    carousel: 'c-carousel--carousel',
    swipeable: 'c-carousel--swipeable',
    dragging: 'c-carousel--dragging',
    cloneSlide: 'c-carousel__slide--clone',
    activeNav: 'is-active',
    activeSlide: 'is-active',
    disabledArrow: 'is-disabled',
  },
}

// Sliders initialization
// Get all instances
const defaultSliders = [...document.querySelectorAll('.js-glide')]
const smallDeviceSliders = [...document.querySelectorAll('.js-glide-um')]
const productSliders = [...document.querySelectorAll('.js-glide-product')]

// Default sliders
defaultSliders.forEach(function (instance) {
  new Glide(instance, {
    ...defaultConfig,
    type: 'carousel',
    perView: 3,
    gap: 24,
    breakpoints: {
      1023: {
        perView: 2,
      },
      799: {
        perView: 1,
        gap: 16,
        peek: {
          before: 16,
          after: 32,
        },
      },
    },
  }).mount()
  instance.classList.add('c-glide--active')
})

// Sliders displayed on small devices
smallDeviceSliders.forEach(function (instance) {
  const slider = new Glide(instance, {
    ...defaultConfig,
    type: 'carousel',
    perView: 1,
    gap: 16,
    peek: {
      before: 16,
      after: 32,
    },
  })

  // Media query change
  function WidthChange(mq) {
    if (mq.matches) {
      slider.mount()
      instance.classList.add('c-glide--active')
    } else {
      slider.destroy()
      instance.classList.remove('c-glide--active')
    }
  }

  // Media query event handler
  if (matchMedia) {
    const mq = window.matchMedia('(max-width: 799px)')
    mq.addListener(WidthChange)
    WidthChange(mq)
  }
})

// Product sliders
productSliders.forEach(function (instance) {
  new Glide(instance, {
    ...defaultConfig,
    type: 'carousel',
    perView: 1,
    gap: 16,
  }).mount()
  instance.classList.add('c-glide--active')
})
