function bindFAQThemeDisplay() {
  if (window.matchMedia('(max-width: 800px)').matches) {
    const elementsToHide = [...document.querySelectorAll('.js-hidden')]
    const nav = document.querySelector('ul.c-faq__nav')
    // Hide themes
    toggleThemes()
    // Get theme buttons
    const themeButtons = [...document.querySelectorAll('.js-display-theme')]
    // Display theme blocks on theme button click
    for (const button of themeButtons) {
      button.addEventListener('click', (event) => {
        const themeId = button.getAttribute('aria-controls')
        const theme = document.getElementById(themeId)
        if (theme) {
          // Display theme block, hide page header and themes nav
          theme.removeAttribute('hidden')
          toggleNavAndHeading(elementsToHide)
        }
      })
    }

    // Manage buttons that close themes: close current theme, display page
    // header and themes nav, scroll to nav top.
    const closeButtons = [...document.querySelectorAll('.js-close-themes')]
    for (const button of closeButtons) {
      button.addEventListener('click', (event) => {
        toggleThemes()
        toggleNavAndHeading(elementsToHide)
        nav.scrollIntoView()
      })
    }
  }
}

function toggleThemes() {
  const themes = [...document.querySelectorAll('.c-faq__wrapper')]
  for (const theme of themes) {
    theme.setAttribute('hidden', true)
  }
}

function toggleNavAndHeading(elems) {
  for (const elem of elems) {
    elem.toggleAttribute('hidden')
  }
}

bindFAQThemeDisplay()
