import { differenceInCalendarDays } from 'date-fns'

import { numberToCurrency } from './number-helpers'

export function camelToSnakeCase(str) {
  return str
    .replace(/URL/g, 'Url')
    .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

export function camelToSnakeCaseObjectKeys(object) {
  return Object.entries(object).reduce(
    (acc, [key, value]) => ({ ...acc, [camelToSnakeCase(key)]: value }),
    {}
  )
}

export function capitalize(label) {
  if (!label) {
    return ''
  }

  label = [...label]
  const first = label.shift()
  return `${first.toUpperCase()}${label.join('').toLowerCase()}`
}

const REGEX_WHITESPACE = /\s+/

// Supplier-ref (MPN) column should be displayed as "Réf. XXX", where "XXX" is a
// short-enough representation of the supplier's name.  We limit this to 10
// codepoints, below which we either pick "initials" (if there are whitespace
// delimiters in the name) or the first 10 codepoints with an ellipsis suffix.
export function computeSupplierHeading(supplierName) {
  const codePoints = [...supplierName]
  return codePoints.length <= 10
    ? supplierName
    : REGEX_WHITESPACE.test(supplierName)
    ? supplierName
        .split(REGEX_WHITESPACE)
        .map((s) => [...s][0])
        .join('')
        .toUpperCase()
    : codePoints.slice(0, 10).join('') + '…'
}

const PRICING_MODES = {
  gross: ['HT', 'Hors-Taxes'],
  net: ['TTC', 'Toutes Taxes Comprises'],
}

const DATE_FORMATTERS = {
  dateTime: new Intl.DateTimeFormat('fr-FR', {
    dateStyle: 'full',
    timeStyle: 'short',
  }),
  shortDateTime: new Intl.DateTimeFormat('fr-FR', {
    dateStyle: 'short',
    timeStyle: 'short',
  }),
  medium: new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium' }),
  short: new Intl.DateTimeFormat('fr-FR', { dateStyle: 'short' }),
  long: new Intl.DateTimeFormat('fr-FR', { dateStyle: 'full' }),
  time: new Intl.DateTimeFormat('fr-FR', { timeStyle: 'short' }),
}

const TIME_FORMATTER = new Intl.DateTimeFormat('fr-FR', {
  hour: 'numeric',
  minute: 'numeric',
})

const NUMBER_FORMATTER = new Intl.NumberFormat('fr-FR')

const SPECIAL_DATE_FORMATS = ['Aujourd’hui', 'Hier', 'Avant-hier']

/**
 * Formats a given date/time using a French locale and an optional format
 * descriptor.
 *
 * @param {Date|String|Number} date - a valid `Date` representation.
 * @param {Object} [options]
 * @param {DateFormat} [options.format = 'short'] - the format of the
 * output.  The `'short'` format would output '16/02/2021', while the
 * `'dateTime'` format would be 'mardi 16 février 2021 à 14:18'.
 *
 * @typedef {'short'|'medium'|'long'|'dateTime'|'time'} DateFormat
 */
export function formatDate(date, { format = 'short', prefixed = false } = {}) {
  if (typeof date !== 'object') {
    date = new Date(date)
  }

  if (format !== 'time') {
    const diff = differenceInCalendarDays(new Date(), date)

    const customFormatedResult = SPECIAL_DATE_FORMATS[diff]

    if (customFormatedResult) {
      const result = prefixed
        ? `d’${customFormatedResult.toLowerCase()}`
        : customFormatedResult
      const time =
        format === 'dateTime' ? ` à ${TIME_FORMATTER.format(date)}` : ''
      return `${result}${time}`
    }
  }

  const result = DATE_FORMATTERS[format].format(date)

  return prefixed ? `du ${result}` : result
}

/**
 * Formats a given date range using a French locale and an optional format
 * descriptor.
 *
 * @param {Date|String|Number} date - a valid `Date` representation.
 * @param {Object} [options]
 * @param {DateFormat} [options.format = 'short'] - the format of the
 * output.  The `'short'` format would output '16/02/2021', while the
 * `'dateTime'` format would be 'mardi 16 février 2021 à 14:18'.
 *
 * @typedef {'short'|'medium'|'long'|'dateTime'|'time'} DateFormat
 */
export function formatDateRange(startsOn, endsOn, { format = 'short' } = {}) {
  let formattedStartsOn = formatDate(startsOn, { format })
  const formattedEndsOn = formatDate(endsOn, { format })

  const now = new Date()
  const diffStartsOn = differenceInCalendarDays(now, startsOn)
  const diffEndsOn = differenceInCalendarDays(now, endsOn)
  const customFormatedStartsOn = SPECIAL_DATE_FORMATS[diffStartsOn]
  const customFormatedEndsOn = SPECIAL_DATE_FORMATS[diffEndsOn]

  if (diffStartsOn === diffEndsOn) {
    return `${
      customFormatedStartsOn ? '' : 'le '
    }${formattedStartsOn.toLowerCase()}`
  }

  if (diffEndsOn === 0) {
    return `depuis ${
      customFormatedStartsOn ? '' : 'le '
    }${formattedStartsOn.toLowerCase()}`
  }

  formattedStartsOn = `${
    customFormatedStartsOn ? 'd’' : 'du '
  }${formattedStartsOn.toLowerCase()}`

  return `${formattedStartsOn} ${
    customFormatedEndsOn ? 'à' : 'au'
  } ${formattedEndsOn.toLowerCase()}`
}

/**
 * A thin wrapper over `formatDate`, using the `'dateTime'` format.
 *
 * @param {Date|String|Number} dateTime: a valid `Date` representation.
 */
export function formatDateTime(dateTime) {
  return formatDate(dateTime, { format: 'dateTime' })
}

export function formatPhone(phone) {
  phone = String(phone || '').trim()
  if (!phone) {
    return ''
  }

  // We're not going to bundle 6MB+ of Google's libphonenumber for this…

  // US phone
  if (phone.startsWith('+1') && phone.length === 12) {
    const country = phone.slice(0, 2)
    const area = phone.slice(2, 5)
    const prefix = phone.slice(5, 8)
    const line = phone.slice(8)
    return `${country} (${area}) ${prefix}-${line}`
  }

  // FR phone
  if (phone.startsWith('+33') || !phone.startsWith('+')) {
    return phone
      .replace('+33', '0')
      .replace(/^00/, '0')
      .replace(/(\d{2})(?=\d)/g, '$1 ')
  }

  return phone
}

// Formats a cents cost as a price, with 2 decimals only if decimals are needed,
// using Euro currency and French formatting.  This mirrors server-side Rails code.
//
// Parameters:
//
// - `cents`: the cost, in cents, as an integer number.
// - `mode`: An option to specify an accessible HTML suffix to append.  There
// are currently two modes allowed: `'net'` for VAT-exclusive mention and `'gross'` for
// VAT-inclusive mention.
//
export function formatPrice(
  cents,
  { mode, stripUnit, unit = stripUnit ? '€' : ' €' } = {}
) {
  const value = cents / 100.0
  const precision = cents % 100 !== 0 ? 2 : 0

  const basis = numberToCurrency(value, {
    unit,
    format: '%n%u',
    precision,
    delimiter: ' ',
  })
  if (!mode) {
    return basis
  }

  const [shortLabel, longLabel] = PRICING_MODES[mode] || []
  if (!shortLabel || !longLabel) {
    return basis
  }

  return `${basis} <abbr title="${longLabel}">${shortLabel}</abbr>`
}

export function pluralize(
  count,
  singular,
  {
    plural,
    none,
    prefix, // { singular, plural },
  } = {}
) {
  if (count === 0 && none !== undefined) {
    return none
  }

  let start = NUMBER_FORMATTER.format(count)
  if (prefix?.singular && count === 1) {
    start = prefix.singular
  } else if ((prefix?.plural || prefix?.singular) && count > 1) {
    start = `${prefix.plural || `${prefix.singular}s`} ${start}`
  }

  return `${start} ${count > 1 ? plural || `${singular}s` : singular}`
}

export function snakeToCamelCase(str) {
  return str
    .replace(/_(\w)/gu, (_, letter) => `${letter.toUpperCase()}`)
    .replace(/Url$/, 'URL')
}

export function toSentence(items) {
  items = Array.from(items)
  if (items.length <= 2) {
    return items.join(' et ')
  }

  return items.slice(0, -1).join(', ') + ` et ${items.slice(-1)}`
}

export function truncate(str, maxLength) {
  const codePoints = Array.from(str)
  if (codePoints.length <= maxLength) {
    return str
  }

  return codePoints.slice(0, maxLength).join('') + '…'
}
