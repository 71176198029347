function bindPasswordToggle() {
  const passwordToggler = [...document.querySelectorAll('.js-show-password')]

  for (const toggler of passwordToggler) {
    toggler.addEventListener('click', handlePasswordVisibility)
  }
}

function handlePasswordVisibility({ target }) {
  // Get the password field
  const password = document.querySelector(
    target.getAttribute('data-show-password')
  )
  if (!password) return

  // Check if the password should be shown or hidden
  if (target.checked) {
    // Show the password
    password.type = 'text'
  } else {
    // Hide the password
    password.type = 'password'
  }
}

bindPasswordToggle()
