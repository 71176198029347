function bindDisclosures() {
  // Get the disclosure buttons
  const disclosures = [...document.querySelectorAll('.js-disclosure-trigger')]

  for (const disclosure of disclosures) {
    // Get the content associated with the button
    const id = disclosure.getAttribute('aria-controls')
    const content = document.querySelector(`#${id}`)

    // If there's no content, don't show the button
    if (!content) return

    // If the content should be hidden, hide it
    if (disclosure.getAttribute('aria-expanded') === 'false') {
      content.setAttribute('hidden', '')
    }
  }
}

// Toggle content on click
function bindDisclosureTriggers() {
  const disclosureTriggers = [
    ...document.querySelectorAll('.js-disclosure-trigger'),
  ]
  for (const trigger of disclosureTriggers) {
    trigger.addEventListener('click', handleDisclosureTrigger)
  }
}

// Only run on elements that have the js-disclosure-trigger class
function handleDisclosureTrigger(event) {
  // Get the content to toggle
  // If no matching content is found, end the function with return
  const id = event.target.getAttribute('aria-controls')
  const content = document.querySelector(`#${id}`)
  if (!content) return

  // Get the name of the group to which the button belongs
  const group = event.target.getAttribute('data-disclosure-group')

  // If the content is visible, hide it
  // Otherwise, show it
  if (event.target.getAttribute('aria-expanded') === 'true') {
    event.target.setAttribute('aria-expanded', false)
    content.setAttribute('hidden', '')
  } else {
    // If the button belongs to a group
    // Get the active button and target
    // And hide it
    if (group) {
      const activeButton = document.querySelector(
        `[data-disclosure-group="${group}"][aria-expanded="true"]`
      )
      if (activeButton) {
        const id = activeButton.getAttribute('aria-controls')
        const activeTarget = document.querySelector(`#${id}`)
        activeButton.setAttribute('aria-expanded', false)
        activeTarget.setAttribute('hidden', '')
      }
    }
    event.target.setAttribute('aria-expanded', true)
    content.removeAttribute('hidden')
  }
}

bindDisclosures()
bindDisclosureTriggers()
