import { func } from 'prop-types'
import { useState } from 'react'

import { formatPrice } from '../../../../shared/js/formatters'
import { VariantPropTypes, VariantsPropTypes } from '../../shared/prop-types'

const Variants = ({
  displayWeight,
  headings,
  onChange,
  selectedId,
  variants,
}) => {
  const crop = variants.length > 3
  const [cropped, setCropped] = useState(crop)
  const colSize = 3 + headings.length
  const wrapperClass = `c-product-table c-product-table--${colSize}-cols${
    cropped ? ' is-cropped' : ''
  }`

  return (
    <div className='u-mb-2@us u-mb-3@fs'>
      <div className={wrapperClass}>
        <div className='u-mb-2' role='grid'>
          <div role='rowgroup'>
            <div role='row'>
              {headings.map((heading, index) => (
                <span
                  className={`c-product-table__${
                    index === 0 ? 'diameter' : 'size'
                  }`}
                  role='columnheader'
                  key={index}
                >
                  {heading}
                </span>
              ))}
              {displayWeight && (
                <span className='c-product-table__weight' role='columnheader'>
                  Poids
                </span>
              )}
              <span className='c-product-table__price' role='columnheader'>
                Prix
              </span>
              <span className='c-product-table__delivery' role='columnheader'>
                Livraison
              </span>
            </div>
          </div>
          <div role='rowgroup'>
            {variants.map((variant) => (
              <Variant
                displayWeight={displayWeight}
                {...variant}
                key={variant.id}
                onChange={handleChange}
                selected={selectedId === variant.id}
              />
            ))}
          </div>
        </div>
      </div>
      {crop && (
        <button
          className='c-btn c-btn--secondary c-btn--light u-wd-full'
          type='button'
          onClick={() => setCropped(!cropped)}
        >
          {cropped ? 'voir plus de tailles' : 'voir moins de tailles'}
        </button>
      )}
    </div>
  )

  // Set selected variant
  function handleChange({ target: { value } }) {
    const variantId = Number(value)
    const updatedVariants = variants.map((variant) => ({
      ...variant,
      selected: variant.id === variantId,
    }))
    onChange(updatedVariants)
  }
}

Variants.propTypes = {
  ...VariantsPropTypes,
  onChange: func.isRequired,
}

export default Variants

// Sub component
const Variant = ({
  cents,
  displayWeight,
  id,
  labels,
  onChange,
  quantity,
  regularCents,
  selected,
  shipping: { label: shippingLabel, highlight: shippingHighlight },
  weight,
}) => {
  const shippingSubTree = (
    <>
      <span>{shippingLabel}</span>
      {quantity > 0 && quantity < 4 && (
        <>
          &nbsp;
          <small className='u-hidden@um'>{`(${quantity} en stock)`}</small>
        </>
      )}
    </>
  )

  return (
    <>
      <input
        checked={selected}
        id={`radio-${id}`}
        onChange={onChange}
        type='radio'
        value={id}
      />
      <label className='c-label c-label--radio' htmlFor={`radio-${id}`}>
        <span role='row'>
          {labels.map((label, index) => (
            <span
              className={`c-product-table__${
                index === 0 ? 'diameter' : 'size'
              }`}
              key={label}
              role='gridcell'
            >
              {label}
            </span>
          ))}
          {displayWeight && (
            <span className='c-product-table__weight' role='gridcell'>
              {formatWeight(weight)}
            </span>
          )}
          <span className='c-product-table__price' role='gridcell'>
            {regularCents ? (
              <>
                <ins>{formatPrice(cents)}</ins>
                <del>{formatPrice(regularCents)}</del>
              </>
            ) : (
              formatPrice(cents)
            )}
          </span>
          {shippingHighlight ? (
            <span
              className='c-product-table__delivery c-product-table__mark'
              role='gridcell'
            >
              <mark>{shippingSubTree}</mark>
            </span>
          ) : (
            <span className='c-product-table__delivery' role='gridcell'>
              {shippingSubTree}
            </span>
          )}
        </span>
      </label>
    </>
  )
}

Variant.propTypes = {
  ...VariantPropTypes,
  onChange: func.isRequired,
}

const weightFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'decimal',
  // unit: 'gram',
  // unitDisplay: 'narrow',
  maximumFractionDigits: 2,
})

function formatWeight(grams) {
  return weightFormatter.format(grams) + ' g'
}
