import { number } from 'prop-types'

const TimerLabel = ({ days, hours, minutes, seconds }) => (
  <>
    {days > 0 && (
      <>
        {days}
        <abbr>j</abbr> 
      </>
    )}
    {(days > 0 || hours > 0) && (
      <>
        {hours}
        <abbr>h</abbr> 
      </>
    )}
    {minutes}
    <abbr>mn</abbr> {seconds}
    <abbr>s</abbr>
  </>
)
TimerLabel.propTypes = {
  days: number.isRequired,
  hours: number.isRequired,
  minutes: number.isRequired,
  seconds: number.isRequired,
}

export default TimerLabel
