import { bool, func, number, string } from 'prop-types'
import { useState } from 'react'

import Dialog from '../shared/Dialog'
import { post } from '../../../../shared/js/json-fetch'
import TextField from '../shared/TextField'

export default function StockAlertButton({
  customerEmail,
  disabled,
  onCreatedAlert,
  variantId,
}) {
  const [active, setActive] = useState(false)
  const [email, setEmail] = useState(customerEmail)
  const [flash, setFlash] = useState(null)
  const [pending, setPending] = useState(null)
  const [alreadyCreated, setAlreadyCreated] = useState(false)

  const flashExtraClassName = flash?.kind === 'error' ? 'c-message--error' : ''

  return (
    <>
      <button
        className='c-btn c-btn--primary c-btn--sticky-cart u-mb-2'
        onClick={openDialog}
        disabled={disabled}
      >
        M’alerter de la disponibilité
      </button>
      <Dialog
        className='right-action-modal'
        onCancel={closeDialog}
        opened={active}
        title='Alerte de disponibilité'
      >
        <span className='c-contextual-information u-txt-center'>
          Vous voulez être informé·e dès que le produit est à nouveau
          disponible ?
        </span>
        {!customerEmail && (
          <fieldset className='right-action-modal__separator'>
            <TextField
              errorMessage='Vous devez renseigner votre e-mail'
              name='email'
              id='email'
              label='Votre e-mail *'
              onChange={setEmail}
              valid={email !== ''}
              type='email'
              required
            />
          </fieldset>
        )}
        {flash && (
          <div className='right-action-modal__separator'>
            <p className={`c-message ${flashExtraClassName}`}>
              {flash.message}
            </p>
          </div>
        )}
        <div className='right-action-modal__btn'>
          {alreadyCreated ? (
            <button className='c-btn c-btn--primary' onClick={closeDialog}>
              C’est compris !
            </button>
          ) : (
            <>
              <button
                className='c-btn c-btn--fill'
                type='reset'
                onClick={closeDialog}
                disabled={pending}
              >
                Finalement non
              </button>
              <button
                className='c-btn c-btn--primary'
                onClick={handleValidate}
                disabled={!email || pending}
              >
                Oui, tenez-moi au courant
              </button>
            </>
          )}
        </div>
      </Dialog>
    </>
  )

  function closeDialog() {
    setActive(false)
    setFlash(null)
    setPending(false)
  }

  async function handleValidate(event) {
    event?.preventDefault()
    setPending(true)
    setFlash({ kind: 'info', message: 'Traitement en cours…' })

    const { error, flash, status } = await post('/alerts', {
      email,
      property_set_id: variantId,
    })

    setPending(false)

    if (error) {
      // Prevent further alert creation on variant when status
      // says it has already been created.
      if (status === 'already_created') {
        onCreatedAlert(variantId)
        setAlreadyCreated(true)
      }
      setFlash(flash)
      return
    }

    onCreatedAlert(variantId)
    closeDialog()
  }

  function openDialog(event) {
    event.preventDefault()
    setActive(true)
  }
}

StockAlertButton.propTypes = {
  // Email can be passed when customer is currently logged in
  customerEmail: string,
  // When alert has already been created for the variant
  disabled: bool,
  // Send back variant ID when alert was created
  onCreatedAlert: func.isRequired,
  // The variant that is not purchasabled
  variantId: number.isRequired,
}
