import { differenceInSeconds, formatISODuration } from 'date-fns'
import { useEffect, useState } from 'react'

import { SalePropTypes } from '../../shared/prop-types'
import TimerLabel from './TimerLabel'

const URL_LABELS = {
  flash_sales: 'de la vente flash',
  private_sales: 'de la vente privée',
  soldes: 'soldés',
}

const TITLES = {
  flash_sales: 'Vente flash',
  private_sales: 'Vente privée',
  soldes: 'Soldes',
}

const SaleCountdown = ({ endsAt, startsAt, url, kind }) => {
  startsAt = new Date(startsAt)
  endsAt = new Date(endsAt)

  const calculateTimeLeft = () => {
    const now = new Date()
    const alreadyStarted = differenceInSeconds(startsAt, now) < 0
    const refDate = alreadyStarted ? endsAt : startsAt
    const diff = differenceInSeconds(refDate, now)
    const timeLeft = {}

    if (diff > 0) {
      timeLeft.days = Math.floor(diff / (60 * 60 * 24))
      timeLeft.hours = Math.floor((diff / (60 * 60)) % 24)
      timeLeft.minutes = Math.floor((diff / 60) % 60)
      timeLeft.seconds = Math.floor(diff % 60)
      // Use `timeLeft` date properties that were just set
      timeLeft.duration = formatISODuration(timeLeft)
      timeLeft.label = alreadyStarted ? 'Fin dans' : 'Début dans'
    } else {
      timeLeft.label = 'Vente terminée !'
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
  })

  return (
    <div className='o-order__first o-layout o-layout--center-y c-flash-sale'>
      <span className='u-visually-hidden'>{TITLES[kind]}</span>
      <div className='u-mb-1@us'>
        <span className='c-flash-sale__ending'>{timeLeft.label}</span>
        <time className='c-flash-sale__countdown' dateTime={timeLeft.duration}>
          <TimerLabel {...timeLeft} />
        </time>
      </div>
      {url && (
        <a href={url} className='c-flash-sale__link c-btn c-btn--secondary'>
          Tous les produits
          <span className='u-block@fm'>{URL_LABELS[kind]}</span>
        </a>
      )}
    </div>
  )
}

SaleCountdown.propTypes = SalePropTypes

export default SaleCountdown
