import Description from './Description'
import mountReactContainer from '../../../../shared/js/react-mounting'
import Photos from './photos/Photos'

document.addEventListener('DOMContentLoaded', () => {
  // Load related products as HTML for "add to cart" dialog display since we
  // don’t want to rebuild associated widget component in JS.
  const relatedProductsHTML = document.getElementById(
    'js-add-to-cart-related-products'
  )?.innerHTML

  mountReactContainer({
    id: 'js-product-description',
    component: Description,
    relatedProductsHTML,
  })

  mountReactContainer({
    id: 'js-product-photos',
    component: Photos,
  })
})
