// Rails-style number formatting
// =============================
//
// This is a straight-up port of Rails’ classic number formatters, using all the same defaults,
// assuming FR locale for them though.

export function currencyToCents(value) {
  return Math.round(
    Number(value.replace(/,/g, '.').replace(/[^0-9.-]+/g, '')) * 100
  )
}

export function numberToCurrency(
  number,
  {
    precision = 2,
    separator = ',',
    delimiter = ' ',
    unit = '€',
    format = '%n %u',
  } = {}
) {
  if (precision === 0) {
    separator = ''
  }
  try {
    return format
      .replace(
        /%n/g,
        numberWithPrecision(number, { precision, delimiter, separator })
      )
      .replace(/%u(\s*)−/g, `−${unit}$1`)
      .replace(/%u/g, unit)
  } catch (e) {
    return String(number || '')
  }
}

export function numberWithDelimiter(
  number,
  { separator = ',', delimiter = ' ' } = {}
) {
  // DEV NOTE: no surrounding try/catch as we cannot see a single code path
  // that would trigger an exception here, so we couldn't test it and get 100% coverage.
  const parts = String(number == null ? '' : number).split('.')
  // Turning a regular hyphen into a minus sign, because typography matters :-)
  parts[0] = parts[0].replace('-', '−')
  // Gotta love this double-lookahead beauty…  NIH, BTW.
  parts[0] = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + delimiter)
  return parts.join(separator)
}

export function numberWithPrecision(
  number,
  { precision = 2, separator = ',', delimiter = ' ' } = {}
) {
  // DEV NOTE: no surrounding try/catch as we cannot see a single code path
  // that would trigger an exception here, so we couldn't test it and get 100% coverage.

  // Inspired by https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round#Decimal_rounding
  const result = Number(
    Math.round(`${number}e+${precision}`) + `e-${precision}`
  )
  if (Number.isNaN(result)) {
    return ''
  }

  return numberWithDelimiter(result.toFixed(precision), {
    separator,
    delimiter,
  })
}
