import { string } from 'prop-types'
import { useState } from 'react'

import {
  camelToSnakeCaseObjectKeys,
  snakeToCamelCase,
} from '../../../../../shared/js/formatters'
import DatePicker from '../../shared/DatePicker'
import Dialog from '../../shared/Dialog'
import { put } from '../../../../../shared/js/json-fetch'

const EventDateAndComment = ({
  comment: initialComment = '',
  eventDate: initialEventDate = '',
  url,
}) => {
  const [{ comment, eventDate }, setValues] = useState({
    comment: initialComment,
    eventDate: initialEventDate,
  })
  // Store last sent values in order to prevent useless server calls
  const [legacyValues, setLegacyValues] = useState({
    comment: initialComment,
    eventDate: initialEventDate,
  })
  const [uxState, setUxState] = useState({
    active: false,
    error: null,
    pending: false,
  })
  const { active, error, pending } = uxState

  return (
    <>
      <div className='c-form__block c-form__block--comment'>
        <div className='c-form__event-content'>
          <span className='c-form__event-title'>
            Date de l’évènement - Commentaire
          </span>
          <p>Important : précisez-nous la date de l’évènement.</p>

          {eventDate && (
            <span className='u-block'>
              <small>Date de l’évènement : {eventDate}</small>
            </span>
          )}
          {comment && (
            <span className='u-block'>
              <small>Commentaire : {comment}</small>
            </span>
          )}
        </div>
        <div className='c-form__event-btn'>
          <button
            className='c-btn c-btn--secondary'
            type='button'
            aria-haspopup='dialog'
            onClick={openDialog}
          >
            Ajouter un commentaire
          </button>
        </div>
      </div>

      <Dialog
        className='right-action-modal'
        onCancel={handleCancel}
        opened={active}
        title='Informations complémentaires'
      >
        <span className='c-contextual-information u-txt-center'>
          Précisez la date d’évènement et rédigez un commentaire si vous avez
          des précisions à fournir
        </span>

        {error && (
          <div className={'c-message c-message--error u-mb-2'}>{error}</div>
        )}
        {/* Event date */}
        <div className='right-action-modal__separator'>
          <div className='u-mb-2'>
            <DatePicker
              date={eventDate}
              id='event-date'
              name='event_date'
              label='Date de l’évènement'
              onChange={handleValueChange}
              placeholder='Choisir une date'
            />
          </div>

          <div className='u-mb-2'>
            <label htmlFor='personalized-message'>
              Un commentaire à nous faire ?
            </label>
            <textarea
              name='comment'
              onChange={handleValueChange}
              value={comment}
            />
          </div>
        </div>

        <div className='right-action-modal__btn'>
          <button
            className='c-btn c-btn--fill'
            onClick={handleCancel}
            type='reset'
          >
            Annuler la date / le commentaire
          </button>
          <button
            className='c-btn c-btn--primary'
            disabled={pending || !validateFields()}
            onClick={handleValidate}
          >
            Valider
          </button>
        </div>
      </Dialog>
    </>
  )

  // Cancel values
  async function handleCancel() {
    const newState = {
      error: null,
      active: false,
    }
    // Send empty values to server only when values have changed
    // since last call.
    if (
      legacyValues.comment !== comment &&
      legacyValues.eventDate !== eventDate
    ) {
      const { error } = await put(url, {
        comment: '',
        event_date: '',
      })
      // Print error if any
      if (error) {
        newState.error = error
        newState.active = true
      } else {
        // otherwise store empty values as legacy (last sent)
        setLegacyValues({ comment: '', eventDate: '' })
      }
    }
    setValues({
      comment: '',
      eventDate: '',
    })
    setUxState(newState)
  }

  function handleValueChange({ target: { name, value } }) {
    const camelizedName = snakeToCamelCase(name)
    setValues({
      comment,
      eventDate,
      [camelizedName]: value,
    })
  }

  // Send data to server, then update list and select created address
  async function handleValidate(event) {
    event.preventDefault()
    setUxState({ active: true, pending: true, error: '' })

    const { flash: { kind, message } = {} } = await put(
      url,
      camelToSnakeCaseObjectKeys({ comment, eventDate })
    )
    if (kind === 'error') {
      setUxState({ active: true, pending: false, error: message })
    } else {
      setUxState({
        active: false,
        pending: false,
        error: null,
      })
      setLegacyValues({ comment, eventDate })
    }
  }

  function openDialog(event) {
    event.preventDefault()
    setUxState({ ...uxState, active: true })
  }

  // Form can be submitted when there is at least one field set.
  function validateFields() {
    return [comment, eventDate].some((val) => Boolean(val?.trim()))
  }
}

EventDateAndComment.propTypes = {
  comment: string,
  eventDate: string,
  url: string.isRequired,
}

export default EventDateAndComment
