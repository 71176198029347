import { number } from 'prop-types'

import picture from '../../../../images/jewellery-bag.jpg'

export default function JewelleryBagLineItem({ count }) {
  if (count === 0) {
    return null
  }

  return (
    <tr
      className='_c-cart-summary__tr c-cart-summary__jewellery-bag'
      data-testid='line_item-jewellery-bag'
    >
      <td className='c-cart-summary__thumbnail _c-cart-summary__td'>
        <img
          alt='Photo d’un écrin et de son sac'
          className='c-cart-summary__preview'
          sizes='(min-width: 50rem) 6.75rem'
          src={picture}
        />
      </td>
      <td className='c-cart-summary__details _c-cart-summary__td' colSpan={4}>
        <p className='c-cart-summary__product'>Ecrins et sacs offerts</p>
        <p className='c-cart-summary__info'>
          PremierCadeau s’engage pour la protection de la planète. Nos jolis
          écrins, sacs à bijoux et cartons d’expédition sont respectueux de
          l’environnement. Si toutefois vous préférez limiter ces emballages
          vous pourrez nous l’indiquer en commentaire en fin de commande.
        </p>
      </td>
    </tr>
  )
}

JewelleryBagLineItem.propTypes = {
  count: number.isRequired,
}
