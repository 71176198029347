import { func, number, object, oneOfType } from 'prop-types'
import Slider from 'react-slick'
import { useLayoutEffect, useRef, useState } from 'react'

import Audio from './Audio'
import { makeArrow, SliderCommonPropTypes } from './utils'
import Photo from './Photo'
import Video from './Video'

import './Photos.scss'
import './slick-1.6.0.min.css'

function dot(index) {
  return (
    <button className='c-glide__bullet'>
      <span className='u-visually-hidden'>Photo {index + 1}</span>
    </button>
  )
}

export default function MainSlider({
  arrowsImageURL,
  currentSlideIndex,
  onChange,
  sliderRef,
  slides,
  subSliderRef,
}) {
  // Retrieve react slick / slider dimensions then apply them to the video if it
  // exists. Start with default dimensions (desktop image width).
  const containerRef = useRef()
  const [dimensions, setDimensions] = useState({ width: 708, height: 708 })

  const PrevArrow = makeArrow({
    alt: 'Photo précédente',
    direction: 'previous',
    icon: arrowsImageURL,
  })
  const NextArrow = makeArrow({
    alt: 'Photo suivante',
    direction: 'next',
    icon: arrowsImageURL,
  })

  useLayoutEffect(() => {
    if (containerRef.current) {
      const { height, width } = containerRef.current.getBoundingClientRect()
      const max = Math.max(height, width)
      // Keep 16:9 proportion
      setDimensions({ height: max, width: (max * 1280) / 720 })
    }
  }, [])

  return (
    <div ref={containerRef}>
      <Slider
        afterChange={onChange}
        asNavFor={subSliderRef}
        className='c-glide__track'
        cssEase='cubic-bezier(0.165, 0.840, 0.440, 1.000)'
        customPaging={dot}
        dots
        dotsClass='c-glide__bullet-wrapper u-hidden@fs'
        infinite
        lazyLoad='progressive'
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        ref={sliderRef}
        slidesToShow={1}
        slidesToScroll={1}
        speed={400}
      >
        {slides.map(({ alt, audio, video, main }, index) =>
          audio ? (
            <Audio url={audio} key={index} />
          ) : video ? (
            <Video
              active={currentSlideIndex === index}
              identifier={video}
              key={index}
              dimensions={dimensions}
            />
          ) : (
            <Photo key={index} alt={alt} {...main} />
          )
        )}
      </Slider>
    </div>
  )
}

MainSlider.propTypes = {
  ...SliderCommonPropTypes,
  currentSlideIndex: number,
  onChange: func,
  sliderRef: func.isRequired,
  // As sub slider and main slider are loaded at the same time, refs are not
  // created on first render. Therefore ref cannot be marked as required.
  // `object` goes for `null`.
  subSliderRef: oneOfType([func, object]),
}
