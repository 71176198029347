import { bool, func, string } from 'prop-types'
import useSound from 'use-sound'
import { useState } from 'react'

import MusicNote from '@material-ui/icons/MusicNote'
import PauseCircleOutline from '@material-ui/icons/PauseCircleOutline'
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline'

export default function Audio({ active, onClick, thumb, url }) {
  const [playing, setPlaying] = useState(false)
  const [play, { pause }] = useSound(url, {
    onend: () => setPlaying(false),
    onpause: () => setPlaying(false),
    onplay: () => setPlaying(true),
  })

  const className = thumb ? 'c-glide__thumbnail' : 'c-slider__preview'
  const icon = thumb ? (
    <MusicNote />
  ) : playing ? (
    <PauseCircleOutline
      fontSize='large'
      alt='Mettre en pause l’extrait audio'
    />
  ) : (
    <PlayCircleOutline fontSize='large' alt='Écouter un extrait audio' />
  )

  return (
    <button
      className={`${className} ${active ? ' is-active' : ''} audio`}
      onClick={handleClick}
    >
      {icon}
    </button>
  )

  function handleClick() {
    if (thumb) {
      onClick?.()
    } else {
      playing ? pause() : play()
    }
  }
}

Audio.propTypes = {
  active: bool,
  onClick: func,
  thumb: bool,
  url: string.isRequired,
}
