/**
 * Main navigation menu management.
 *
 * Handles the dynamic injection of full-fledged subcats in the main <nav>, the
 * event binding of the hamburger and intermediate categories on mobile, etc.
 *
 * This works on evergreens and IE9+ (e.g. careful use of DOMTokenList/classList
 * and closest() polyfilling if need be).
 */

import { closest, on } from '../../shared/js/delegation'

document.addEventListener('DOMContentLoaded', () => {
  fleshOutNavigation()
  wireMobileNav()

  // The DOM is not up-to-date yet (it hasn't been deserialized), so let the
  // browser breathe it in before we traverse it for active-toplevel detection.
  requestAnimationFrame(setCurrentNavEntry)
})

// SEO recommendation
// Differ subnavigation presence in top-of-body <nav>
async function fleshOutNavigation() {
  // Get the main <nav> container (the first one is the "hamburger")
  const nav = document.getElementById('js-navigation')
  if (!nav) {
    return
  }

  // For each entry in there with a data-load attribute, try and get the
  // matching container for full-fledged subnav HTML and move it over.
  for (const fleshable of nav.querySelectorAll('[data-load]')) {
    const fullContent = document.getElementById(
      fleshable.getAttribute('data-load')
    )
    if (fullContent) {
      fleshable.innerHTML = fullContent.innerHTML
      fullContent.remove()
    }
  }
}

function isDesktopMenuDisplayed() {
  return (
    // No matchMedia? No chance this is a smartphone/tablet browser…
    !window.matchMedia ||
    // The following MQ is used for deciding to display the desktop menu. See
    // _components.nav.scss for details.
    window.matchMedia(
      '(hover: hover) and (min-width: 50em), (-ms-high-contrast: none) and (min-width: 50em)'
    ).matches
  )
}

// Detects the currently-active toplevel category based on the current location
// path, and adds the proper ARIA marker to it, which in turns triggers some
// CSS.
function setCurrentNavEntry() {
  const path = window.location.pathname
  const catLink = document.querySelector(`#js-navigation a[href="${path}"]`)
  const topLevelLink =
    catLink && closest(catLink, '.c-nav__item')?.querySelector('a.c-nav__link')
  topLevelLink?.setAttribute('aria-current', true)
}

function wireMobileNav() {
  const nav = document.getElementById('js-navigation')
  const cta = document.getElementById('js-mobile-nav-cta')
  console.assert(
    nav && cta,
    'Navigation or Hamburger are missing from the DOM!'
  )

  // Hamburger click: toggle nav
  const doc = document.documentElement
  cta.addEventListener('click', () =>
    doc.classList.toggle('has-unfolded-header')
  )

  // First and second sublevels (expanding)
  wireSubLevel({ selectorSuffix: 'item', statePrefix: 'first' })
  wireSubLevel({ selectorSuffix: 'sub-item', statePrefix: 'second' })

  // Nav back links (way out)
  on(nav, 'click', '.c-nav__back-button', (e) => {
    e.preventDefault()
    const activeParent = closest(e.target, '.is-active')
    activeParent?.classList.remove('is-active')

    if (nav.classList.contains('second-level-is-displayed')) {
      nav.classList.remove('second-level-is-displayed')
    } else {
      nav.classList.remove('first-level-is-displayed')
    }
  })

  function wireSubLevel({ selectorSuffix, statePrefix }) {
    const mainSelector = `.c-nav__${selectorSuffix}`

    on(nav, 'click', `${mainSelector} > a.with-children`, (e) => {
      // If we're on desktop with the menu displayed (which may not always be
      // the case due to responsiveness, DevTools, etc.) let regular clicks go
      // through.
      if (isDesktopMenuDisplayed()) {
        return
      }
      e.preventDefault()
      nav.classList.toggle(`${statePrefix}-level-is-displayed`)
      closest(e.target, mainSelector)?.classList.toggle('is-active')
    })
  }
}
