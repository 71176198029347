import 'whatwg-fetch'

// Performs a (potentially polyfilled) JSON-oriented
// [Fetch](https://developer.mozilla.org/fr/docs/Web/API/Fetch_API)
// call, and either rejects on a non-OK (non-2xx) promise
// or resolves to the response object.
//
// For testing purposes, this supports a third optional argument
// that lets us do Dependency Injection (mocks for `document` and `fetch``
// itself, basically).
export default function jsonFetch(
  url,
  options = {},
  { fetch = window.fetch } = {}
) {
  const { csrfToken, ...fetchOptions } = options
  const tokenValue =
    csrfToken ||
    window.document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content')

  options = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': tokenValue,
    },
    credentials: 'same-origin',
    ...fetchOptions,
  }

  if (typeof options.body !== 'string') {
    options.body = JSON.stringify(options.body)
  }

  return fetch(url, options)
}

function makeJSONCall(name, method = name.toUpperCase()) {
  const result = function (url, body, options = {}) {
    return (
      jsonFetch(url, { body, method, ...options })
        .then((res) => res.json())
        // Error flashes can be returned server-side; we harmonize client-side
        // (e.g. network) errors to produce the same format. So a Fetch-JSON call
        // never throws / rejects.
        .catch(({ message }) => ({ flash: { kind: 'error', message } }))
        // We also provide normalized booleans for success/error to simplify call
        // sites.
        .then((res) => {
          const error = res.flash?.kind === 'error'
          const success = res.data && !error
          return { ...res, success, error }
        })
    )
  }
  result.displayName = name
  return result
}

export const drop = makeJSONCall('drop', 'DELETE')
export const get = makeJSONCall('get')
export const patch = makeJSONCall('patch')
export const post = makeJSONCall('post')
export const put = makeJSONCall('put')
