import { func, string } from 'prop-types'
import { useEffect, useRef, useState } from 'react'

const MAX_CHARS_PER_LINE = 56
const MAX_LINES = 5

// Optional gift message input, with live feedback on max chars / lines and
// controlled input that auto-wraps and truncates accordingly.
const GiftCardComment = ({ comment: initialComment = '', onChange }) => {
  // For caret preservation, we need raw-DOM access to the `<textarea>`
  const inputRef = useRef()
  const [comment, setComment] = useState(normalizeMessage(initialComment))
  // If `caretToRestore` isn’t `null`, we need to restore caret postion
  // post-render, using an effect.
  const [caretToRestore, setCaretToRestore] = useState(null)
  // Feedback about reamining chars and lines
  const feedback = computeFeedback(computeRemainingSpace(comment))

  // The caret-preserving magic™
  useEffect(() => {
    if (caretToRestore && inputRef.current) {
      inputRef.current.selectionStart = inputRef.current.selectionEnd =
        caretToRestore
      setCaretToRestore(null)
    }
  }, [caretToRestore])

  return (
    <>
      <label htmlFor='personalized-message'>
        Votre message{' '}
        <span className='c-form__textarea-countdown' data-testid='feedback'>
          {feedback}
        </span>
      </label>
      <textarea
        id='personalized-message'
        name='order[gift_card_comment]'
        onChange={handleChange}
        ref={inputRef}
        value={comment}
      />
    </>
  )

  function handleChange({ target: { value } }) {
    const comment = normalizeMessage(value)
    setComment(comment)
    // If we altered the typed-in value for the controlled field, React’s
    // rendering mechanics would result in a caret being pushed at the very end
    // of the text; we need to compensate by doing a manual caret restore.
    if (comment !== value) {
      setCaretToRestore(inputRef.current?.selectionStart)
    }
    onChange &&
      onChange({ target: { name: 'gift_card_comment', value: comment } })
  }
}

GiftCardComment.propTypes = {
  comment: string,
  onChange: func,
}

// Provide codepoint-based text length.
function actualLength(text) {
  return Array.from(text).length
}

// Textual feedback on remaining chars/lines, if any.
function computeFeedback({ remainingChars, remainingLines }) {
  if (remainingChars <= 0 || remainingLines <= 0) {
    return 'Vous avez atteint la taille maximum pour votre message'
  }

  const charsInfo =
    remainingChars > 1
      ? `Il vous reste ${remainingChars} caractères`
      : 'Plus qu’un caractère'
  const linesInfo =
    remainingLines > 1 ? `${remainingLines} lignes` : 'la dernière ligne'

  return `${charsInfo} sur ${linesInfo}`
}

// Compute remaining chars overall and remaining lines.  As any “spent” line
// counts for its whole char count, lines are accounted as “full-length” no
// matter what.
function computeRemainingSpace(comment) {
  const maxChars = MAX_CHARS_PER_LINE * MAX_LINES
  const lines = comment.split('\n')
  const lineCount = lines.length
  const chars = Math.max(
    actualLength(comment.replace(/\n/g, '')),
    (lineCount - 1) * MAX_CHARS_PER_LINE + actualLength(lines[lineCount - 1])
  )
  return {
    remainingChars: Math.max(maxChars - chars, 0),
    remainingLines: Math.max(MAX_LINES - lineCount + 1, 0),
  }
}

// Normalizes an original text into a proper amount of lines.  Takes care of
// auto-wrapping stuff and truncating if need be.  This is used internally by
// the change handler, plus at init time.
function normalizeMessage(text) {
  // Remove leading whitespace / newlines and sequences of 2+ usual horizontal
  // whitespace.
  text = (text || '').replace(/^\s+/, '').replace(/[ \t]+/g, ' ')

  // No point going further on an empty text :-|
  if (text === '') {
    return text
  }

  // Wrap line overflows, joining with next lines if not empty.
  const lines = text.split('\n')

  // (We don't cache lines.length because the loop may insert lines)
  for (let lineIndex = 0; lineIndex < lines.length; lineIndex++) {
    // Unicode-Aware Code™
    const line = Array.from(lines[lineIndex])
    // Line is short enough: next!
    if (line.length <= MAX_CHARS_PER_LINE) {
      continue
    }

    // Find the space/tab farther right **within the allowed width**, if any.  I
    // wish we had a more legible API available, but predicate-based
    // right-to-left traversals don't exist, so we go with a `reduceRight`
    // (sigh).
    const lastSpace = line.reduceRight(
      (maxPos, cp, index) =>
        maxPos ||
        ((cp === '\t' || cp === ' ') && index <= MAX_CHARS_PER_LINE
          ? index
          : undefined),
      undefined
    )

    let remainder
    if (lastSpace !== undefined) {
      remainder = line.slice(lastSpace + 1).join('')
      lines[lineIndex] = line.slice(0, lastSpace).join('')
    } else {
      remainder = line.slice(MAX_CHARS_PER_LINE).join('')
      lines[lineIndex] = line.slice(0, MAX_CHARS_PER_LINE).join('')
    }

    if ((lines[lineIndex + 1] || '') !== '') {
      // Next line has text or we’re on the last line: merge with next line or
      // add a line.
      lines[lineIndex + 1] = `${remainder} ${lines[lineIndex + 1]}`
    } else {
      // Next line exists but is empty: perserve what is likely an intentional
      // vertical spacing by inserting a new line right after ours (before the
      // empty line).
      lines.splice(lineIndex + 1, 0, remainder)
    }
  }

  // Strip extra lines, if any.
  lines.splice(MAX_LINES)

  return lines.join('\n')
}

export default GiftCardComment
