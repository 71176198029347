import { any } from 'underscore'

import { AddressPropTypes } from '../../shared/prop-types'

const AddressLines = ({
  address,
  city,
  civility,
  company,
  complement,
  country,
  firstName,
  lastName,
  mobilePhone,
  zipCode,
  separator = ' - ',
}) => {
  return (
    <>
      <strong className='c-address__label'>
        {civility === 'mrs' ? 'Mme' : 'M.'} {firstName} {lastName}
      </strong>
      {mobilePhone}
      <br />
      {company && (
        <>
          {company}
          <br />
        </>
      )}
      {address}
      {separator}
      {zipCode} {city}
      <br />
      {complement && (
        <>
          {complement}
          <br />
        </>
      )}
      {country}
    </>
  )
}

AddressLines.propTypes = {
  ...AddressPropTypes,
  separator: any,
}

export default AddressLines
