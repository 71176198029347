import { format as dateFnsFormat, parse as dateFnsParse } from 'date-fns'
import { DateUtils } from 'react-day-picker'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { fr } from 'date-fns/locale'
import { func, string } from 'prop-types'

const MONTHS = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
]
const WEEKDAYS_LONG = [
  'Dimanche',
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
]
const WEEKDAYS_SHORT = ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam']

const FORMAT = 'dd/MM/yyyy'

const DatePicker = ({ id, date, label, name, onChange, placeholder }) => {
  const dayPickerProps = {
    locale: 'fr',
    months: MONTHS,
    weekdaysLong: WEEKDAYS_LONG,
    weekdaysShort: WEEKDAYS_SHORT,
  }

  return (
    <>
      <label htmlFor={id}>{label}</label>
      <DayPickerInput
        format={FORMAT}
        formatDate={formatDate}
        parseDate={parseDate}
        inputProps={{ id, name }}
        onDayChange={handleChange}
        value={date}
        dayPickerProps={dayPickerProps}
        placeholder={placeholder}
        component={(props) => (
          <>
            <input {...props} />
            <div className='c-helper c-helper--calendar'>
              <span className='u-visually-hidden'>
                Afficher/masquer le calendrier
              </span>
            </div>
          </>
        )}
      />
    </>
  )

  function handleChange(date) {
    if (!onChange) {
      return
    }

    const value = formatDate(date, FORMAT, null)
    onChange({ target: { name, value } })
  }

  function parseDate(str, format, _) {
    const parsed = dateFnsParse(str, format, new Date(), { locale: fr })
    if (DateUtils.isDate(parsed)) {
      return parsed
    }
    return undefined
  }

  function formatDate(date, format, _) {
    return dateFnsFormat(date, format, { locale: fr })
  }
}

DatePicker.propTypes = {
  id: string.isRequired,
  date: string,
  label: string.isRequired,
  name: string,
  onChange: func,
  placeholder: string,
}

export default DatePicker
