import { string } from 'prop-types'
import { useState } from 'react'

import AddressLines from '../shared/AddressLines'
import { AddressPropTypes } from '../../shared/prop-types'
import Dialog from '../shared/Dialog'
import FormSetup from '../../../../shared/js/components/FormSetup'

const AddressDialogLink = ({ url, ...address }) => {
  const [opened, setOpened] = useState(false)

  return (
    <>
      <a
        href='#'
        className='c-link c-btn c-btn--fill'
        onClick={toggleDialog}
        aria-haspopup='dialog'
      >
        Supprimer
      </a>
      <Dialog
        className='center-modal'
        onCancel={toggleDialog}
        opened={opened}
        title='Suppression de l’addresse'
      >
        <div className='u-font-sm'>
          <form className='u-txt-center' action={url} method='post'>
            <FormSetup method='DELETE' />
            <p className='u-mb-1'>
              Souhaitez-vous vraiment supprimer l’adresse suivante :
            </p>
            <div className='u-mb-2'>
              <AddressLines {...address} separator={<br />} />
            </div>
            <button
              className='c-btn c-btn--secondary'
              type='button'
              onClick={toggleDialog}
            >
              Annuler
            </button>
            <button
              name='button'
              type='submit'
              className='c-btn c-btn--primary'
            >
              Oui, supprimer cette adresse
            </button>
          </form>
        </div>
      </Dialog>
    </>
  )

  function toggleDialog(event) {
    event.preventDefault()
    setOpened(!opened)
  }
}

AddressDialogLink.propTypes = {
  ...AddressPropTypes,
  url: string.isRequired,
}

export default AddressDialogLink
